import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { completeFiled } from "../../reducers/registerClient/actions";
import { change } from "redux-form";
import { Image } from "./styles";
import Alert from "../../assets/alert.svg";
import Address from "./TabsScreens/Address/Address";
import DeliveryAddress from "./TabsScreens/DeliveryAdress/DeliveryAddress";
import Invoice from "./TabsScreens/Invoice/Invoice";
import General from "./TabsScreens/General/General";
import Observation from "./TabsScreens/Observation/Observation";
import DocumentAttachments from "./TabsScreens/DocumentAttachments/DocumentAttachments";
import Contact from "./TabsScreens/Contact/Contact";
import { useStatePersist } from "use-state-persist";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./styles.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 6,
  },
  indicator: {
    top: "0px",
    backgroundColor: "#DFAB0E",
    padding: 2,
  },
}));

const useStyle = makeStyles({});

export default function SimpleTabs({ registerClientComplete }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [generalComplete, setGeneralComplete] = useState(true);
  const [observationComplete, setObservationComplete] = useState(true);
  const [addressComplete, setAddressComplete] = useState(true);
  const [addDeliveryComplete, setAddDeliveryComplete] = useState(true);
  const [documentComplete, setDocumentComplete] = useState(true);
  const [invoice, setInvoice] = useState(true);
  const [contact, setContact] = useState(true);

  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (
      generalComplete &&
      addDeliveryComplete &&
      addressComplete &&
      documentComplete &&
      invoice &&
      contact &&
      registerClientComplete
    ) {
      dispatch(completeFiled(true));
    } else {
      dispatch(completeFiled(false));
    }
  }, [
    generalComplete,
    observationComplete,
    registerClientComplete,
    addDeliveryComplete,
    addressComplete,
    documentComplete,
    invoice,
    contact,
    dispatch,
  ]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#F0F0F0", color: "black", padding: 5 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
          aria-label="simple tabs example"
        >
          <Tab
            label="Geral"
            icon={generalComplete === false ? <Image src={Alert} /> : <Image />}
            {...a11yProps(0)}
          />
          <Tab
            label="Endereço"
            icon={addressComplete === false ? <Image src={Alert} /> : <Image />}
            {...a11yProps(1)}
          />
          <Tab
            label="Endereço de entrega"
            icon={
              addDeliveryComplete === false ? <Image src={Alert} /> : <Image />
            }
            {...a11yProps(2)}
          />
          <Tab
            label="Contatos"
            icon={contact === false ? <Image src={Alert} /> : <Image />}
            {...a11yProps(3)}
          />
          <Tab
            label="NF-e/NFS-e/CT-e"
            icon={invoice === false ? <Image src={Alert} /> : <Image />}
            {...a11yProps(4)}
          />
          <Tab
            label="Anexos/Documentos"
            icon={
              documentComplete === false ? <Image src={Alert} /> : <Image />
            }
            {...a11yProps(5)}
          />
          <Tab
            label="Observações"
            // icon={
            //   observationComplete === false ? <Image src={Alert} /> : <Image />
            // }
            {...a11yProps(6)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <General setGeneralComplete={setGeneralComplete} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Address setAddressComplete={setAddressComplete} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DeliveryAddress setAddDeliveryComplete={setAddDeliveryComplete} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Contact setContactComplete={setContact} contactValue={contact} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Invoice setInvoice={setInvoice} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DocumentAttachments setDocumentComplete={setDocumentComplete} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Observation setObservationComplete={setObservationComplete} />
      </TabPanel>
    </div>
  );
}
