import styled from "styled-components";

export const ButtonRegister = styled.button`
  background-color: #0b2138;
  width: 279px;
  height: 56px;
  border: 0px;
  border-radius: 6px;
  font-size: 21.42px;
  color: #dfab0e;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px;
  margin-bottom: 6px;
`;

export const InputSearch = styled.input`
  width: 442px;
  height: 60px;
  border: 1px solid #86888e;
  border-radius: 6px;
  margin-right: 30px;
  font-size: 25px;

  ::-webkit-input-placeholder {
    font-size: 25px;
  }
`;

export const ContainerLabels = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const SvgButton = styled.img`
  margin: 07px;
`;
