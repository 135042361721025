import styled from "styled-components";

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin: auto;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 60%;
`;

export const TextRegister = styled.h3`
  color: #0b2138;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  margin: 5px;
`;

export const ButtonSave = styled.button`
  background-color: ${(props) => props.color};
  width: 93px;
  height: 33px;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  margin-left: 13px;
`;

export const Paragraf = styled.p`
  color: red;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-left: 13px;
`;

export const ContainerParagraf = styled.div`
  width: 270px;
`;

export const Form = styled.div`
  display: flex;
  /* justify-content: space-around; */
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

export const ContainerSwitchButton = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
`;

export const ContainerDescriptions = styled.div`
  display: flex;
  /* justify-content: space-between; */
  margin-left: 21.5%;
  margin-top: 8px;
`;
