import { useEffect } from "react";
import { Row, Container } from "./styles";
import cepIcon from "../../../../assets/cepIcon.svg";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { useStatePersist as useState } from "use-state-persist";
import { useSelector, useDispatch } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";

function Address({ setAddressComplete }) {
  const dispatch = useDispatch();


  const [address, setAddress] = useState("@addressFields", {
    enderecoCEP: "",
    enderecoCidade: "",
    enderecoBairro: "",
    enderecoUf: "",
    endereco: "",
    enderecoNumero: "",
    enderecoComplemento: "",
    emailRomaneio: "",
  });


  function searchCep(value) {
    setAddress({
      ...address,
      enderecoCEP: value,
      enderecoBairro: "",
      enderecoCidade: "",
      enderecoUf: "",
      endereco: "",
    });

    if (value.length >= 8) {
      axios
        .get(`https://viacep.com.br/ws/${value}/json`)
        .then((res) => {
          let zipCode = res.data.cep;
          let addressAPi = res.data.logradouro;
          let neighborhood = res.data.bairro;
          let city = res.data.localidade;
          let uf = res.data.uf;

          setAddress({
            ...address,
            enderecoCEP: zipCode,
            enderecoBairro: neighborhood,
            enderecoCidade: city,
            enderecoUf: uf,
            endereco: addressAPi,
          });
        })
        .catch((error) => console.log(error));
    }
  }

  useEffect(() => {
    if (
      address.enderecoCEP !== "" &&
      address.enderecoCidade !== "" &&
      address.enderecoBairro !== "" &&
      address.enderecoUf !== "" &&
      address.endereco !== "" &&
      address.enderecoNumero !== "" &&
      address.enderecoComplemento !== "" &&
      address.emailRomaneio !== ""
    ) {
      setAddressComplete(true);
      dispatch(sendFormAction(address));
    } else {
      setAddressComplete(false);
      dispatch(sendFormAction({}));
    }
  }, [address, setAddressComplete, dispatch]);

  return (
    <Container>
      <Row name="row-1">
        <TextField
          variant="outlined"
          type="text"
          name="zipCode"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          label="CEP"
          value={address.enderecoCEP}
          onChange={(event) => searchCep(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <img src={cepIcon} width="23" alt="cep" height="23" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="outlined"
          name="city"
          label="Cidade"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          type="text"
          value={address.enderecoCidade}
          onChange={(event) =>
            setAddress({ ...address, enderecoCidade: event.target.value })
          }
        />

        <TextField
          variant="outlined"
          name="neighborhood"
          label="Bairro"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          value={address.enderecoBairro}
          onChange={(event) =>
            setAddress({ ...address, enderecoBairro: event.target.value })
          }
        />

        <TextField
          variant="outlined"
          name="uf"
          label="UF"
          style={{ marginTop: 12, width: 80, margin: 6 }}
          value={address.enderecoUf}
          onChange={(event) =>
            setAddress({ ...address, enderecoUf: event.target.value })
          }
        />
      </Row>

      <Row name="row-2">
        <TextField
          variant="outlined"
          name="address"
          value={address.endereco}
          onChange={(event) =>
            setAddress({ ...address, endereco: event.target.value })
          }
          label="Endereço"
          style={{ marginTop: 12, width: 360, margin: 6 }}
        />

        <TextField
          variant="outlined"
          label="Nº"
          name="number"
          style={{ marginTop: 12, width: 90, margin: 6 }}
          value={address.enderecoNumero}
          onChange={(event) =>
            setAddress({ ...address, enderecoNumero: event.target.value })
          }
        />

        <TextField
          variant="outlined"
          label="Complemento"
          name="complement"
          style={{ marginTop: 12, width: 360, margin: 6 }}
          value={address.enderecoComplemento}
          onChange={(event) =>
            setAddress({ ...address, enderecoComplemento: event.target.value })
          }
        />
      </Row>

      <Row name="row-3">
        <TextField
          label="E-mail Romeneo"
          variant="outlined"
          name="emailR"
          style={{ marginTop: 12, width: 400, margin: 6 }}
          value={address.emailRomaneio}
          onChange={(event) =>
            setAddress({ ...address, emailRomaneio: event.target.value })
          }
        />
      </Row>
    </Container>
  );
}

export default Address;
