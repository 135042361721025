import { useState, useEffect } from "react";
import api from "../../config/api";
import {
  Container,
  Header,
  Text,
  Row,
  ButtonSave,
  InfoCliente,
  SelectedProducts,
  ContainerProduct,
  Button,
  TableProduct,
  TableProductHead,
  TableProductRow,
  ContainerTableRow,
  ItemTableHead,
  ItemTableRow,
  ImageSvg,
  ContainerRows,
  ContainerSelecteds,
  Footer,
  ItemAmount,
  ItemFooter,
  TableSelectedRow,
  CustomTR,
  Div,
  ContainerInfo,
  Client,
  ClientInfo,
  TextHead,
  HeadTable,
  RowsTable,
  ItemRow,
  TextRow,
} from "./styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import "./styles.css";
import Order from "../../assets/order.svg";
import Car from "../../assets/car.svg";
import Plus from "../../assets/plus.svg";
import Minimal from "../../assets/Minimal.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "./Modal";
import { useDispatch } from "react-redux";
import {
  sendProductsActions,
  saveOrdersActions,
} from "../../reducers/newOrders/actions";
import Loading from "../../components/Loading/Loading";
import { useDebounce } from "use-debounce";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";

function NewProduct() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [sendSelecteds, setSendSelecteds] = useState(null);
  const customer = useSelector((state) => state.newOrders.newOrder.customer);
  const { token } = useSelector((state) => state.config.auth);
  const [produtos, setProdutos] = useState();
  const [objReverse, setObjectReveserse] = useState(null);
  const [reverse, setReverse] = useState(false);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [search, setSearch] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [debouncedText] = useDebounce(text, 700);

  useEffect(() => {
    setSearch(debouncedText);
  }, [debouncedText, setSearch]);

  useEffect(() => {
    setLoading(true);
    const options = {
      pageOffset: 0,
      codigo: "",
      descricao: search,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    api.post(`/api/v1/pedido/produto/busca`, options, config).then((res) => {
      setProdutos(res.data.produtos);
      setFilteredProduct(res.data.produtos);
      console.log(res.data.produtos);
      setLoading(false);
      setSelectedProducts(
        res.data.produtos?.map((i) => {
          return { ...i, hidden: true };
        })
      );
    });
  }, [token, search]);

  useEffect(() => {
    const data = Object.assign([], produtos).reverse();
    setObjectReveserse(data);
  }, [produtos]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const selectRow = (array, id) => {
    let dupArray = [];
    dupArray = array === "all" ? [...filteredProduct] : [...selectedProducts];
    const newArray = dupArray.map((item, rowIndex) => {
      return {
        ...item,
        selected: item.codigo === id ? !item.selected : item.selected,
      };
    });

    array === "all"
      ? setFilteredProduct(newArray)
      : setSelectedProducts(newArray);
  };

  const addSelectedProducts = () => {
    const dupArray = [...filteredProduct];
    const newArray = dupArray.map((item, rowIndex) => {
      return { ...item, hidden: item.selected ? true : item.hidden };
    });
    setFilteredProduct(newArray);
    //debugger
    const selectedProductArray = selectedProducts.map((item, rowIndex) => {
      return {
        ...item,
        hidden: newArray[rowIndex].selected
          ? false
          : selectedProducts[rowIndex].hidden,
        selected: false,
      };
    });
    setSelectedProducts(selectedProductArray);
  };

  const removeSelectedProducts = () => {
    const dupArray = [...selectedProducts];
    const newArray = dupArray.map((item, rowIndex) => {
      return { ...item, hidden: item.selected ? true : item.hidden };
    });
    setSelectedProducts(newArray);

    const allProducts = filteredProduct.map((item, rowIndex) => {
      return {
        ...item,
        hidden: newArray[rowIndex].hidden
          ? false
          : filteredProduct[rowIndex].hidden,
        selected: false,
      };
    });
    setFilteredProduct(allProducts);
  };

  const calcTotal = () => {
    let arrayOfValues = selectedProducts?.filter((i) => i.hidden === false);
    arrayOfValues = arrayOfValues?.map(
      (i) => (i.quantity ? i.quantity : 1) * (i.value || 0)
    );

    if (arrayOfValues?.length === 0) return "0.00";

    return arrayOfValues?.reduce((a, b) => a + b);
  };

  useEffect(() => {
    const resultArray = filteredProduct
      .filter(
        (item) =>
          item.descricao.toLowerCase().includes(search.toLowerCase()) ||
          item.codigo.toString().toLowerCase().includes(search.toLowerCase())
      )
      .map((i) => i.code);

    setFilteredProduct(
      filteredProduct.map((i) => {
        return {
          ...i,
          hidden: resultArray.includes(i.code) ? false : true,
        };
      })
    );
  }, [search]);

  const increase = (e, index) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const dupArray = [...selectedProducts];
    const newArray = dupArray.map((item, rowIndex) => {
      return {
        ...item,
        quantity:
          rowIndex === index
            ? (item.quantity ? item.quantity : 1) + 1
            : item.quantity,
      };
    });
    setSelectedProducts(newArray);
  };

  const decrease = (e, index) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const dupArray = [...selectedProducts];
    const newArray = dupArray.map((item, rowIndex) => {
      return {
        ...item,
        quantity:
          rowIndex === index
            ? (item.quantity ? item.quantity : 1) - 1 < 1
              ? 1
              : item.quantity - 1
            : item.quantity,
      };
    });
    setSelectedProducts(newArray);
  };

  function messageConfirm() {
    const confirmSelecteds = selectedProducts.filter(
      (item) => item.hidden === false
    );

    dispatch(sendProductsActions(confirmSelecteds));
    setOpen(true);
  }

  function funcReverse() {
    // setReverse(!reverse);
  }

  return (
    <>
      <Container>
        <Header>
          <div style={{ display: "flex" }}>
            <ArrowBackIcon style={{ marginRight: 5 }} />

            <Text
              fontSize="20px"
              cursor="pointer"
              onClick={() =>
                history.push(`/dados/pedido/cliente/${customer.id}`)
              }
            >
              Voltar
            </Text>
          </div>
          <Text fontSize="32px" cursor="unset">
            Selecione os Pedidos
          </Text>
        </Header>

        <Row>
          <InfoCliente>
            <ContainerInfo>
              <ClientInfo>Cliente:</ClientInfo>
              <Client>
                {customer?.nomeFantasia} | {customer?.codigo}
              </Client>
            </ContainerInfo>
          </InfoCliente>
        </Row>

        <Row>
          <ContainerProduct height="478px" width="504px">
            <SelectedProducts>
              <TextField
                variant="outlined"
                style={{ margin: 12, width: 306 }}
                placeholder="Pesquisar produto"
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
              <Button
                width="93px"
                height="45px"
                border="4px"
                style={{
                  cursor:
                    filteredProduct.filter((item) => item.selected === true)
                      .length > 0
                      ? "pointer"
                      : "unset",
                }}
                background={
                  filteredProduct.filter((item) => item.selected === true)
                    .length > 0
                    ? "#41825b"
                    : "#68C18B"
                }
                onClick={() => addSelectedProducts()}
              >
                <div style={{ display: "flex", margin: "auto" }}>
                  <ImageSvg src={Plus} alt="plus" />
                  <ImageSvg src={Car} alt="car" />
                </div>
              </Button>
            </SelectedProducts>
            <TableProduct>
              <TableProductHead width="99%" height="33px" mt="10px">
                <HeadTable>
                  <Text>Código</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
                <Divider orientation="vertical" flexItem />

                <HeadTable>
                  <Text>Descrição</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
                <Divider orientation="vertical" flexItem />

                <HeadTable>
                  <Text>Valor</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
              </TableProductHead>

              {loading === true && <Loading loading={loading} />}

              <ContainerTableRow height="330px">
                {loading === false &&
                  // reverse === false &&
                  filteredProduct?.map((item, index) => {
                    const string = "ESPANADOR ELETROSTATICO";
                    if (item.hidden) return;
                    return (
                      <RowsTable
                        key={index}
                        onClick={() => selectRow("all", item.codigo)}
                        selected={item.selected}
                      >
                        <ItemRow>
                          <TextRow>{item.codigo}</TextRow>
                        </ItemRow>

                        <Tooltip title={item.descricao}>
                          <ItemRow>
                            <TextRow>
                              {item?.descricao?.length > 20
                                ? `${item.descricao.slice(0, 15)}...`
                                : item.descricao}
                            </TextRow>
                          </ItemRow>
                        </Tooltip>

                        <ItemRow>
                          <TextRow>R$ {item.value || "0.00"}</TextRow>
                        </ItemRow>
                      </RowsTable>
                    );
                  })}

                {/* {loading === false &&
                  reverse === true &&
                  objReverse?.map((item, index) => {
                    if (item.hidden) return;
                    return (
                      <RowsTable
                        key={index}
                        onClick={() => selectRow("all", index)}
                        selected={item.selected}
                      >
                        <ItemRow>
                          <TextRow>{item.codigo}</TextRow>
                        </ItemRow>

                        <ItemRow>
                          <TextRow>{item.descricao}</TextRow>
                        </ItemRow>

                        <ItemRow>
                          <TextRow>R$ {item.value}</TextRow>
                        </ItemRow>
                      </RowsTable>
                    );
                  })} */}
              </ContainerTableRow>
            </TableProduct>
          </ContainerProduct>

          <ContainerProduct height="478px" width="659px">
            <SelectedProducts>
              <Button
                width="93px"
                height="45px"
                border="4px"
                onClick={null}
                style={{
                  cursor:
                    selectedProducts?.filter((item) => item.selected === true)
                      .length > 0
                      ? "pointer"
                      : "unset",
                }}
                background={
                  selectedProducts?.filter((item) => item.selected === true)
                    .length > 0
                    ? "#d6bfbf"
                    : "#3e3131"
                }
                onClick={() => removeSelectedProducts()}
              >
                <div style={{ display: "flex", margin: "auto" }}>
                  <ImageSvg src={Minimal} alt="minimal" />
                  <ImageSvg src={Car} alt="car" />
                </div>
              </Button>
              <Text
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 12,
                }}
                fontSize="28px"
              >
                Produtos selecionados
              </Text>
            </SelectedProducts>

            <ContainerSelecteds>
              <TableProductHead width="99%" height="33px" mt="10px">
                <HeadTable>
                  <Text>Código</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
                <Divider orientation="vertical" flexItem />

                <HeadTable style={{ width: 170 }}>
                  <Text>Descrição</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
                <Divider orientation="vertical" flexItem />

                <HeadTable>
                  <Text>&ensp;Qnt.</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
                <Divider orientation="vertical" flexItem />

                <HeadTable>
                  <Text>Valor un.</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
                <Divider orientation="vertical" flexItem />

                <HeadTable>
                  <Text>Total</Text>
                  <ImageSvg src={Order} alt="Order" onClick={funcReverse} />
                </HeadTable>
              </TableProductHead>
              <ContainerTableRow height="310px">
                {selectedProducts &&
                  selectedProducts.map((item, index) => {
                    if (item.hidden) return;

                    return (
                      <RowsTable
                        key={index}
                        onClick={() => selectRow("selected", item.codigo)}
                        selected={item.selected}
                        style={{
                          width: "96%",
                          height: "14px",
                        }}
                      >
                        <ItemRow
                          style={{
                            width: "120.95px",
                          }}
                        >
                          <TextRow>
                            {" "}
                            &ensp;&ensp;
                            {item.codigo}
                          </TextRow>
                        </ItemRow>

                        <Tooltip title={item.descricao}>
                          <ItemRow
                            style={{
                              width: "174px",
                            }}
                          >
                            <TextRow>
                              &ensp;&ensp;
                              {item?.descricao?.length > 20
                                ? `${item.descricao.slice(0, 15)}...`
                                : item.descricao}
                            </TextRow>
                          </ItemRow>
                        </Tooltip>

                        <ItemRow
                          style={{
                            width: "122px",
                          }}
                        >
                          <TextRow>
                            &ensp;&ensp;&ensp;&ensp;
                            <button onClick={(e) => decrease(e, index)}>
                              -
                            </button>
                            &ensp;&ensp;
                            {item.quantity || 1}
                            &ensp;&ensp;
                            <button onClick={(e) => increase(e, index)}>
                              +
                            </button>
                            &ensp;&ensp;
                          </TextRow>
                        </ItemRow>

                        <ItemRow>
                          <TextRow>
                            &ensp;&ensp; &ensp;&ensp; R${item.value || "0,00"}
                          </TextRow>
                        </ItemRow>

                        <ItemRow>
                          <TextRow>
                            &ensp;&ensp;&ensp;&ensp; R${" "}
                            {item.quantity === undefined
                              ? item.value || 0.0
                              : item.quantity * item.value || 0.0}
                          </TextRow>
                        </ItemRow>
                      </RowsTable>
                    );
                  })}
              </ContainerTableRow>
            </ContainerSelecteds>
            <Footer>
              <ItemFooter>
                Total Produtos:{" "}
                {selectedProducts
                  ?.filter((i) => i.hidden === false)
                  .reduce((a, b) => a + (b["quantity"] || 1), 0)}
              </ItemFooter>
              <ItemFooter>Valor Total: {calcTotal()}</ItemFooter>

              <Button
                style={{
                  marginBottom: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                background="#68C18B"
                width="150px"
                height="45px"
                border="none"
                color="white"
                onClick={() => messageConfirm()}
              >
                Confirmar
              </Button>
            </Footer>
          </ContainerProduct>
        </Row>

        <Modal open={open} setOpen={setOpen} client={customer?.socialName} />
      </Container>
    </>
  );
}

export default NewProduct;
