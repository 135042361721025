import styled from "styled-components";
import File from "../../../../assets/file.svg";

export const ContainerList = styled.div`
  width: 1051px;
  height: 49px;
  border-radius: 2px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const Image = styled.img`
  margin-right: 2px;
`;

export default function DocumentList({ descricao }) {
  return (
    <ContainerList>
      <Image style={{
        padding:2
      }}  src={File} alt="file" width="23" height="23" />
      {`${descricao}`}
    </ContainerList>
  );
}
