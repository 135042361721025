import React, { useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Croos from "../../../../assets/croosWhite.svg";
import SwitchButton from "../../../../components/SwitchButton/SwitchButton";
import { change } from "redux-form";
import InputMask from "react-input-mask";

import { ButtonAdd, SvgButton } from "./styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Checked from "../../../../assets/checked.svg";
import { Field, reduxForm } from "redux-form";
import ContactList from "./ContactList";
import { useStatePersist } from "use-state-persist";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 940,
    height: 398,
    backgroundColor: "#F0F0F0",
    padding: 40,
    margin: "auto",
  },
}));

function Contact({ setContactComplete, contactValue }) {
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [formEdit, setFormEdit] = React.useState({});
  const [completeValues, setCompleteValues] = React.useState(false);

  const dispatch = useDispatch();

  const [contact, setContact] = useState({
    nome: "",
    cargo: "",
    telefone: "",
    email: "",
    cidade: "",
    recebeNotaPorEmail: false,
    recebeBoletoPorEmail: false,
    ativo: true,
    celular: "",
  });
  const [arrayContact, setArrayContact] = useStatePersist("@arrayContact", {
    contatos: [],
  });

  console.log("recebeNotaPorEmail", contact.recebeNotaPorEmail);

  useEffect(() => {
    if (edit === true) {
      console.log("formEdit", formEdit);
      setContact(formEdit);
    }
  }, [edit, formEdit]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const { nome, cargo, telefone, celular, email, cidade } = contact;
    if (
      nome !== "" &&
      cargo !== "" &&
      telefone !== "" &&
      celular !== "" &&
      email !== "" &&
      cidade !== ""
    ) {
      setCompleteValues(true);
    } else {
      setCompleteValues(false);
    }
  }, [contact]);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  function onSubmit(e) {
    e.preventDefault();
    const { nome, cargo, telefone, celular, email, cidade } = contact;

    if (
      nome !== "" &&
      cargo !== "" &&
      telefone !== "" &&
      celular !== "" &&
      email !== "" &&
      cidade !== ""
    ) {
      contact.id = Math.floor(Math.random() * 90 + 10);
      setArrayContact({
        ...arrayContact,
        contatos: [...arrayContact.contatos, contact],
      });
    }

    setContact({
      nome: "",
      cargo: "",
      tel: "",
      telefone: "",
      email: "",
      cidade: "",
      recebeNotaPorEmail: false,
      recebeBoletoPorEmail: false,
      celular: "",
    });

    handleClose();
    setEdit(false);
  }

  function onEdit(e) {
    e.preventDefault();
    const { id, nome, cargo, telefone, celular, email, cidade } = contact;

    if (
      nome !== "" &&
      cargo !== "" &&
      telefone !== "" &&
      celular !== "" &&
      email !== "" &&
      cidade !== ""
    ) {
      const filter = arrayContact.contatos.map((contact) =>
        contact.id === id ? (contact = formEdit) : contact
      );

      setArrayContact({
        ...arrayContact,
        contatos: [...filter],
      });
    }

    setContact({
      nome: "",
      cargo: "",
      tel: "",
      telefone: "",
      email: "",
      cidade: "",
      recebeNotaPorEmail: false,
      recebeBoletoPorEmail: false,
    });

    handleClose();
    setEdit(false);
  }

  useEffect(() => {
    if (arrayContact.contatos.length !== 0) {
      setContactComplete(true);
      dispatch(sendFormAction(arrayContact));
    } else {
      setContactComplete(false);
      dispatch(sendFormAction([]));
    }
  }, [arrayContact.length, setContactComplete, dispatch, arrayContact]);

  const body = (
    <Container style={modalStyle} className={classes.paper}>
      <Title>Cadastro de Contato</Title>
      <form onSubmit={edit === false ? onSubmit : onEdit}>
        <Row style={{ marginTop: "12px" }}>
          <TextField
            name="companyName"
            label="Nome do contato"
            type="text"
            variant="outlined"
            style={{
              marginTop: 12,
              width: 442,
              margin: 6,
            }}
            value={contact.nome}
            onChange={(event) =>
              edit
                ? setFormEdit({
                    ...contact,
                    nome: event.target.value,
                  })
                : setContact({
                    ...contact,
                    nome: event.target.value,
                  })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {contact.nome.length > 3 ? (
                      <img src={Checked} width="23" alt="checked" height="23" />
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="charge"
            label="Cargo"
            type="text"
            variant="outlined"
            style={{
              marginTop: 12,
              width: 438,
              margin: 6,
            }}
            value={contact.cargo}
            onChange={(event) =>
              edit
                ? setFormEdit({
                    ...contact,
                    cargo: event.target.value,
                  })
                : setContact({
                    ...contact,
                    cargo: event.target.value,
                  })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {contact.cargo.length > 2 ? (
                      <img src={Checked} width="23" alt="checked" height="23" />
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />{" "}
        </Row>
        <Row>
          <InputMask
            mask="(99) 9999-9999"
            value={contact.telefone}
            onChange={(event) =>
              edit
                ? setFormEdit({
                    ...contact,
                    telefone: event.target.value,
                  })
                : setContact({
                    ...contact,
                    telefone: event.target.value,
                  })
            }
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                name="telefone"
                label="Telefone"
                variant="outlined"
                type="text"
                style={{
                  marginTop: 12,
                  width: 212,
                  margin: 6,
                }}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton edge="end">
                //         {contact.telefone.length > 13 ? (
                //           <img
                //             src={Checked}
                //             width="23"
                //             alt="checked"
                //             height="23"
                //           />
                //         ) : null}
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            )}
          </InputMask>

          <InputMask
            mask="(99) 9 9999-9999"
            value={contact.celular}
            onChange={(event) =>
              edit
                ? setFormEdit({
                    ...contact,
                    celular: event.target.value,
                  })
                : setContact({
                    ...contact,
                    celular: event.target.value,
                  })
            }
          >
            {(inputProps) => (
              <TextField
                name="celular"
                label="Celular"
                {...inputProps}
                variant="outlined"
                type="text"
                style={{
                  marginTop: 12,
                  width: 212,
                  margin: 6,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        {contact.celular.length > 16 ? (
                          <img
                            src={Checked}
                            width="23"
                            alt="checked"
                            height="23"
                          />
                        ) : null}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </InputMask>

          <TextField
            name="email"
            variant="outlined"
            label="E-mail"
            type="email"
            style={{
              marginTop: 12,
              width: 438,
              margin: 6,
            }}
            value={contact.email}
            onChange={(event) =>
              edit
                ? setFormEdit({
                    ...contact,
                    email: event.target.value,
                  })
                : setContact({
                    ...contact,
                    email: event.target.value,
                  })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {contact.email !== "" ? (
                      <img src={Checked} width="23" alt="checked" height="23" />
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <Row>
          <TextField
            name="city"
            label="Cidade"
            variant="outlined"
            type="text"
            style={{
              marginTop: 12,
              width: 438,
              margin: 6,
            }}
            value={contact.cidade}
            onChange={(event) =>
              edit
                ? setFormEdit({
                    ...contact,
                    cidade: event.target.value,
                  })
                : setContact({
                    ...contact,
                    cidade: event.target.value,
                  })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    {contact.cidade.length > 3 ? (
                      <img src={Checked} width="23" alt="checked" height="23" />
                    ) : null}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ContainerSwitch>
            <Text>Receber nota por e-mail</Text>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={contact.recebeNotaPorEmail}
                    onChange={(event) => {
                      setContact({
                        ...contact,
                        recebeNotaPorEmail: event.target.checked,
                      });
                    }}
                  />
                }
              />
            </FormGroup>
          </ContainerSwitch>
          <ContainerSwitch>
            <Text>Receba boleto por e-mail</Text>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={contact.recebeBoletoPorEmail}
                    onChange={(event) => {
                      setContact({
                        ...contact,
                        recebeBoletoPorEmail: event.target.checked,
                      });
                    }}
                  />
                }
              />
            </FormGroup>
          </ContainerSwitch>
          <ButtonSave
            style={{ backgroundColor: completeValues ? "green" : "#aeaeae" }}
          >
            Salvar
          </ButtonSave>
        </Row>
      </form>
    </Container>
  );

  return (
    <div>
      <ButtonAdd onClick={handleOpen}>
        <SvgButton src={Croos} color="white" />
      </ButtonAdd>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ border: "none" }}
      >
        {body}
      </Modal>
      {arrayContact !== []
        ? arrayContact.contatos.map((item) => (
            <ContactList
              item={item}
              handleOpen={handleOpen}
              setEdit={setEdit}
              setFormEdit={setFormEdit}
            />
          ))
        : null}
    </div>
  );
}

export const Container = styled.div`
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h2`
  color: gray;
  font-family: "Roboto", sans-serif;
  font-size: 29px;
`;
export const Row = styled.div`
  display: flex;
  height: 100%;
`;
export const ButtonSave = styled.button`
  background-color: #aeaeae;
  width: 93px;
  height: 33px;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
`;

const ContainerSwitch = styled.div`
  margin-left: 12px;
`;

const Text = styled.p`
  width: 109px;
  font-family: "Open Sans";
  font-size: 12px;
  color: #767676;
`;

export default reduxForm({
  form: "contact", // a unique identifier for this form
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: false,
})(Contact);
