import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  ContainerButton,
  ButtonAdd,
  SvgButton,
  Form,
  ButtonSave,
  ContainerInfo,
  Row,
  ContainerTable,
  // Table,
  // TableHeader,
  // HeaderItem,
  // RowItem,
  // TableRow,
} from "./styles";
import Croos from "../../assets/croosWhite.svg";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import Table from "./Table/Table";
import api from "../../config/api";
import Pagination from "../../components/Pagination/Pagination";

function Product() {
  const history = useHistory();

  const row = useSelector((state) => state.newOrders.orders);
  const [searchOption, setSearchOption] = useState("all");
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(row);
  const { token } = useSelector((state) => state.config.auth);
  const [rowsData, setRowsData] = useState(null);
  const [offset, setOfsset] = useState(0);
  const LIMIT = 5;

  useEffect(() => {
    const options = {
      limit: LIMIT,
      offset: offset,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    api.post(`/api/v1/pedido/busca`, options, config).then((res) => {
      setRowsData(res.data);
    });
  }, [offset, token]);

  function redirect(data) {
    history.push({
      pathname: "/detalhe/pedido",
      state: data,
    });
  }

  return (
    <>
      <ContainerButton>
        <ButtonAdd onClick={() => history.push("/novo/produto")}>
          <SvgButton src={Croos} /> Novo Pedido
        </ButtonAdd>
      </ContainerButton>
      <ContainerInfo>
        <Row>
          <Form>
            <FormControl
              variant="outlined"
              style={{ marginTop: 12, width: 323, marginLeft: 2 }}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Filtrar por
              </InputLabel>
              <Select
                native
                label="Filtar por"
                onChange={(e) => setSearchOption(e.target.value)}
              >
                <option value={"nomeFantasia"}>Nome Fantasia</option>
                <option value={"nomeSocial"}>Razão Social</option>
                <option value={"cpfCnpj"}>CNPJ/CPF</option>
                <option value={"codigo"}>Código do Cliente</option>
                <option value={"numeroPedido"}>Número do pedido</option>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ marginTop: 12, width: 470, marginLeft: 12 }}
              onChange={(e) => setSearch(e.target.value)}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                digite a informação do cliente
              </InputLabel>
              <OutlinedInput
                endAdornment={<InputAdornment position="end"></InputAdornment>}
                labelWidth={211}
              />
            </FormControl>
          </Form>
        </Row>
        <Form>
          <FormControl
            variant="outlined"
            style={{ marginTop: 13, width: 320, marginRigth: 111 }}
          >
            <TextField
              id="date"
              label="Data Negociação"
              type="date"
              variant="outlined"
              defaultValue="2017-05-24"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <FormControl
            variant="outlined"
            style={{ marginTop: "1.9%", width: 320, marginLeft: 15 }}
            name="segment"
            labelWidth={600}
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Todos status
            </InputLabel>
            <Select native label="Filtar por">
              <option aria-label="None" value="" />
              <option value={"Nome Fantasia"}>Nome Fantasia</option>
              <option value={"Razão Social"}>Razão Social</option>
              <option value={"CNPJ/CPF"}>CNPJ/CPF</option>
              <option value={"Código do Cliente"}>Código do Cliente</option>
              <option value={"Número do pedido"}>Número do pedido</option>
            </Select>
          </FormControl>

          <ButtonSave type="button">Pesquisar</ButtonSave>
        </Form>
      </ContainerInfo>

      <ContainerTable
        style={{
          marginBottom: 30,
        }}
      >
        <Table rowsData={rowsData} />
      </ContainerTable>
      <Pagination limit={LIMIT} offset={offset} setOffset={setOfsset} />
    </>
  );
}

export default Product;
