import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import {
  sendFormCompleteAction,
  EditModeAction,
  EditModeSuccessAction,
} from "../../../reducers/registerClient/actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Note from "../../../assets/writing.svg";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ContainerTable, Image, ContainerImage } from "./styles";
import "./styles.css";
import api from "../../../config/api";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "gray",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //     backgroundColor: '#CCCCCC',
  // },
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables({ search }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const token = useSelector((state) => state.config.auth.token);
  const LIMIT = 7;
  const [offset, setOfsset] = useState(0);
  let config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let sendInfo = {
    limit: LIMIT,
    offset: offset,
    nomeFantasia: `${search}`,
  };

  useEffect(() => {
    setLoading(true);

    api
      .post(`/api/v1/cliente/busca`, sendInfo, config)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [offset, token, search]);

  useEffect(() => {
    setOfsset(0);
  }, [search]);

  useEffect(() => {
    localStorage.removeItem("@useStatePerist:@generalFields");
    localStorage.removeItem("@useStatePerist:@arrayObservation");
    localStorage.removeItem("@useStatePerist:@arrayDocuments");
    localStorage.removeItem("@useStatePerist:@arrayContact");
    localStorage.removeItem("@useStatePerist:@invoiceFields");
    localStorage.removeItem("@useStatePerist:@addressFields");
    localStorage.removeItem("@useStatePerist:@addressDeliveryFields");
    localStorage.removeItem("@useStatePerist:@contactFields");
    localStorage.removeItem("@useStatePerist:@generalFields");
    localStorage.removeItem("@useStatePerist:@documentsFields");
    localStorage.removeItem("@useStatePerist:@observationFields");
    localStorage.removeItem("@useStatePersist:@checkbox");
    localStorage.removeItem("@useStatePersist:@checboxRegister");
  }, []);

  function submitEdit(payload) {
    const id = payload.id;
    dispatch(EditModeAction(id, token, history));
    // history.push(`/editar/cliente/${payload.id}`);
  }

  return (
    <ContainerTable>
      {loading ? (
        <p>Carregando..</p>
      ) : (
        <>
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <TableHead id="table-head">
                <TableRow>
                  <StyledTableCell>Editar</StyledTableCell>
                  <StyledTableCell>Razão Social</StyledTableCell>
                  <StyledTableCell>Celular</StyledTableCell>
                  <StyledTableCell>E-mail</StyledTableCell>
                  <StyledTableCell>Cidade</StyledTableCell>
                  <StyledTableCell>Segmento</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {data?.map((row) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      <ContainerImage
                        onClick={() => submitEdit(row)}
                        style={{ cursor: "pointer" }}
                      >
                        <Image src={Note} />
                      </ContainerImage>
                    </StyledTableCell>
                    <StyledTableCell>{row?.nomeFantasia}</StyledTableCell>
                    <StyledTableCell>
                      {row?.contatos?.telefone && row?.contatos[0]?.telefone}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.contatos?.email && row?.contatos[0]?.email}
                    </StyledTableCell>
                    <StyledTableCell>{row?.enderecoCidade}</StyledTableCell>
                    <StyledTableCell>Tecnologia</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination limit={LIMIT} offset={offset} setOffset={setOfsset} />
        </>
      )}
    </ContainerTable>
  );
}
