import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  sendFormAction,
  sendFormGeneral,
} from "../../../../reducers/registerClient/actions";
import { Row } from "./styles";
import TextField from "@material-ui/core/TextField";
import { useStatePersist } from "use-state-persist";
import InputMask from "react-input-mask";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Checked from "../../../../assets/checked.svg";
import { cpf, cnpj } from "cpf-cnpj-validator";
import cadeado from "../../../../assets/cadeado.svg";

function General({ setGeneralComplete }) {
  const dispatch = useDispatch();
  const [documentPersonValid, setdocumentPersonValid] = useStatePersist(
    "@documentPerson",
    null
  );

  const [general, setGeneral] = useStatePersist("@generalFields", {
    nomeFantasia: "",
    cnpjCpf: "",
    potencialCompra: null,
    matriz: "TESTE",
    inscricaoEstadualRg: "",
    emailPrincipal: "",
    telefonePrincipal: "",
    celularPrincipal: "",
  });

  console.log("potencialCompra", parseInt(general.potencialCompra));

  useEffect(() => {
    const {
      nomeFantasia,
      cnpjCpf,
      potencialCompra,
      matriz,
      inscricaoEstadualRg,
      emailPrincipal,
      telefonePrincipal,
      celularPrincipal,
    } = general;

    if (
      nomeFantasia !== "" &&
      cnpjCpf !== "" &&
      potencialCompra !== null &&
      matriz !== "" &&
      inscricaoEstadualRg !== "" &&
      emailPrincipal !== "" &&
      telefonePrincipal !== "" &&
      celularPrincipal !== ""
    ) {
      setGeneralComplete(true);
      general.potencialCompra = parseInt(general.potencialCompra);
      dispatch(sendFormAction(general));
    } else {
      setGeneralComplete(false);
    }
  }, [general, setGeneralComplete, dispatch]);

  useEffect(() => {
    const document = general.cnpjCpf;
    if (document !== "") {
      if (document.length < 15) {
        const cpfValidation = cpf.isValid(document);
        setdocumentPersonValid(cpfValidation);
      } else {
        const cpfValidation = cnpj.isValid(document);
        setdocumentPersonValid(cpfValidation);
      }
    }
  }, [general, setdocumentPersonValid]);

  return (
    <form>
      <Row>
        <TextField
          name="companyName"
          value={general.nomeFantasia}
          placeholder="Insira a razão social"
          label="Razão Social"
          variant="outlined"
          type="text"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          onChange={(event) =>
            setGeneral({ ...general, nomeFantasia: event.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  {general.nomeFantasia.length > 2 && (
                    <img src={Checked} width="17" alt="checked" height="17" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputMask
          mask={
            general.cnpjCpf.length < 15
              ? "999.999.999-999"
              : "99.999.999/9999-99"
          }
          value={general.cnpjCpf}
          error={documentPersonValid === false}
          onChange={(event) =>
            setGeneral({ ...general, cnpjCpf: event.target.value })
          }
          helperText={
            documentPersonValid === false ? "Credênciais inválidas" : ""
          }
          maskChar=""
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              name="document"
              label="CPF/CNPJ"
              style={{ marginTop: 12, width: 210, margin: 6 }}
              type="text"
              placeholder="Insira o CNPJ ou CPF"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      {documentPersonValid === true && (
                        <img
                          src={Checked}
                          width="17"
                          alt="checked"
                          height="17"
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
        <TextField
          name="buyingPotential"
          label="Potencial de Compra"
          value={general.potencialCompra}
          type="number"
          style={{ marginTop: 12, width: 240, margin: 6 }}
          placeholder="Potencial de Compra"
          variant="outlined"
          onChange={(event) =>
            setGeneral({ ...general, potencialCompra: event.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  {general.potencialCompra !== null && (
                    <img src={Checked} width="17" alt="checked" height="17" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="headQuarters"
          label="Matriz"
          type="text"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          variant="outlined"
          disabled={true}
          value={general.matriz}
          onChange={(event) =>
            setGeneral({ ...general, matriz: event.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <img src={cadeado} width="16" alt="checked" height="16" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Row>
      <Row>
        <TextField
          name="subscription"
          label="Inc.Estadual/Identidade"
          type="text"
          style={{ marginTop: 12, width: 300, margin: 6 }}
          variant="outlined"
          value={general.inscricaoEstadualRg}
          placeholder="Insira o n° da inscrição/Isento ou 0"
          onChange={(event) =>
            setGeneral({ ...general, inscricaoEstadualRg: event.target.value })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  {general.inscricaoEstadualRg.length > 5 && (
                    <img src={Checked} width="17" alt="checked" height="17" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="E-mail Principal"
          type="text"
          style={{ marginTop: 12, width: 200, margin: 6 }}
          variant="outlined"
          value={general.emailPrincipal}
          onChange={(event) => {
            setGeneral({ ...general, emailPrincipal: event.target.value });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  {general.emailPrincipal.length > 5 && (
                    <img src={Checked} width="17" alt="checked" height="17" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Telefone Principal"
          type="text"
          style={{ marginTop: 12, width: 200, margin: 6 }}
          variant="outlined"
          value={general.telefonePrincipal}
          onChange={(event) => {
            setGeneral({ ...general, telefonePrincipal: event.target.value });
          }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton edge="end">
          //         {general.telefonePrincipal.length > 5 && (
          //           <img src={Checked} width="17" alt="checked" height="17" />
          //         )}
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
        />

        <TextField
          label="Celular Principal"
          type="text"
          style={{ marginTop: 12, width: 200, margin: 6 }}
          variant="outlined"
          value={general.celularPrincipal}
          onChange={(event) => {
            setGeneral({ ...general, celularPrincipal: event.target.value });
          }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton edge="end">
          //         {general.celularPrincipal.length > 5 && (
          //           <img src={Checked} width="17" alt="checked" height="17" />
          //         )}
          //       </IconButton>
          //     </InputAdornment>
          //   ),
          // }}
        />
      </Row>
    </form>
  );
}

export default General;
