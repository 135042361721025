import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 900,
  },
});

export default function CustomizedTables() {
  const classes = useStyles();
  const rows = useSelector((state) => state.reports.reportsView);
  // console.log("STATE", state);

  return (
    <TableContainer
      component={Paper}
      style={{
        heigth: "500px",
      }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Proposta</StyledTableCell>
            <StyledTableCell align="right">Pedido</StyledTableCell>
            <StyledTableCell align="right">Nota</StyledTableCell>
            <StyledTableCell align="right">Faturamento</StyledTableCell>
            <StyledTableCell align="right">Parceiro</StyledTableCell>
            <StyledTableCell align="right">Vendedor</StyledTableCell>
            <StyledTableCell align="right">Cod.Produto</StyledTableCell>
            <StyledTableCell align="right">Desc.Produto</StyledTableCell>
            <StyledTableCell align="right">Quantidade</StyledTableCell>
            <StyledTableCell align="right">Valor Unitário</StyledTableCell>
            <StyledTableCell align="right">Valor Nota</StyledTableCell>
            <StyledTableCell align="right">Cidade</StyledTableCell>
            <StyledTableCell align="right">UF</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && (
            <>
              {rows.map((row) => (
                <StyledTableRow key={row?.name}>
                  <StyledTableCell align="right" style={{ height: 7 }}>
                    {row?.numeroProposta}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row?.pedido}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.numeroNota}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.dataFaturamento}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.parceiro}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.vendedor}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.codigoProduto}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.descricaoProduto}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.quantidade}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.valorUnitario}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row?.valorNota}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row?.cidade}</StyledTableCell>
                  <StyledTableCell align="right">{row?.uf}</StyledTableCell>
                </StyledTableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
