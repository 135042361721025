import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../../config/api";
import {
  Container,
  Description,
  SignInButton,
  Form,
  Text,
  LinkText,
} from "./styles";
import { Line } from "../../components/index";
import Checked from "../../assets/checked.svg";
import {
  loginAction,
  successAction,
  errorAction,
  clearRequest,
} from "../../reducers/config/actions";

import TextField from "@material-ui/core/TextField";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

export default function Login() {
  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Login - Datatem";
  }, []);

  const [data, setData] = useState({ email: "", password: "" });

  const [disableUser, setDisableUser] = useState(true);
  const [disablePassword, setDisablePassword] = useState(true);

  useEffect(() => {
    if (data.email.length < 5) {
      setDisableUser(false);
    } else {
      setDisableUser(true);
    }

    if (data.password.length < 5) {
      setDisablePassword(false);
    } else {
      setDisablePassword(true);
    }
  }, [data, disableUser, disablePassword]);

  function onSubmit(event) {
    event.preventDefault();

    api
      .post("/auth/login", data)
      .then((res) => {
        dispatch(successAction("Logado com Sucesso!"));
        dispatch(loginAction(res.data));
        dispatch(clearRequest());
        history.push("/home");
      })
      .catch((error) => {
        console.log(error.message);
        if (error.message === "Request failed with status code 401") {
          dispatch(errorAction("Usuário ou senha inválido"));
          dispatch(clearRequest());
        } else {
          dispatch(errorAction("Erro ao logar"));
          dispatch(clearRequest());
        }
      });
  }

  return (
    <Container>
      <Text>{t("Login.title")}</Text>
      <Line />
      <Description>{t("Login.descriptionLineOne")}</Description>
      <Description>{t("Login.descriptionLineTwo")}</Description>

      <Form onSubmit={onSubmit}>
        <TextField
          label="Usuário"
          type="email"
          variant="outlined"
          value={data.user}
          onChange={(event) => setData({ ...data, email: event.target.value })}
          style={{
            marginTop: 12,
            width: 420,
            margin: 6,
            backgroundColor: "white",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  {disableUser && (
                    <img src={Checked} width="23" alt="checked" height="23" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />{" "}
        <TextField
          label="Senha"
          type="password"
          value={data.password}
          onChange={(event) =>
            setData({ ...data, password: event.target.value })
          }
          variant="outlined"
          style={{
            marginTop: 12,
            width: 420,
            margin: 6,
            backgroundColor: "white",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  {disablePassword && (
                    <img src={Checked} width="23" alt="checked" height="23" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />{" "}
        <SignInButton
          disabled={disableUser && disablePassword ? false : true}
          style={
            disableUser && disablePassword
              ? { backgroundColor: "#DFAB0E" }
              : { backgroundColor: "#EFD587" }
          }
          onClick={onSubmit}
        >
          {t("Login.buttonSignIn")}
        </SignInButton>
      </Form>

      <LinkText> {t("Login.helpText")}</LinkText>
    </Container>
  );
}
