import React from "react";
import "./styles.css";
import leftArrow from "../../assets/setaEsquerda.svg";
import rightArrow from "../../assets/setaDireita.svg";

const MAX_ITEMS = 5;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;

const Pagination = ({ limit, total = 50, offset, setOffset }) => {
  const current = offset ? offset / limit + 1 : 1;
  const pages = Math.ceil(total / limit);
  const first = Math.max(current - MAX_LEFT, 1);

  function onPageChange(page) {
    setOffset((page - 1) * limit);
  }

  return (
    <div className="container">
      <ul className="pagination">
        {current > 1 && <li>
            <button
              className="guia"
              onClick={() => onPageChange(current - 1)}
              disabled={current === 1}
            >
              <img src={leftArrow} alt="anterior" />
            </button>
          </li>
        }
        {Array.from({ length: Math.min(MAX_ITEMS, pages) })
          .map((_, index) => index + first)
          .map((page) => (
            <li key={page}>
              <button
                onClick={() => onPageChange(page)}
                className={page === current ? "pagination__item--active" : null}
              >
                {page}
              </button>
            </li>
          ))}
        <li>
          <button
            className="guia"
            onClick={() => onPageChange(current + 1)}
            disabled={current === pages}
          >
            <img src={rightArrow} alt="proximo" />
          </button>
        </li>
      </ul>

      {/* <h4>{total} registros</h4> */}
    </div>
  );
};

export default Pagination;
