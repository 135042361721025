import styled from "styled-components";

export const Container = styled.div`
  background-color: #0a2136;
  display: flex;
  justify-content: space-around;
  padding: 16px;

`;
export const ContainerLogo = styled.div``;
export const Logo = styled.img``;
export const SvgButton = styled.img`
    margin:07px;
    
`;

export const ContainerImage = styled.div`
  display:flex;
  cursor:pointer;



`;

export const ContainerText = styled.div`
  display:flex;
  color:white;
  font-size:22px;
  align-items:center;
  font-weight:bold;
  cursor:pointer;

`