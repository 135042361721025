import styled from "styled-components";

export const Container = styled.div``;

export const ContainerList = styled.div`
  width: 1051px;
  height: 49px;
  border-radius: 2px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  margin-top:4px;
`;

export const ContainerDate = styled.div`
  border-right: 0.7px solid gray;
  padding: 12.2px;
`;

export const Text = styled.p`
  margin-left: 5px;
`;
