import { useEffect, useState } from "react";
import {
  Container,
  Table,
  TitleTable,
  Title,
  RowTable,
  RowItem,
  ButtonAdd,
  SvgButton,
  RowContainer,
} from "./styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import order from "../../../assets/order.svg";

// import Croos from "../../assets/croosWhite.svg";
// import { selectCustomerActions } from "../../reducers/newOrders/actions";

function TableList({ rowsData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [reverse, setReverse] = useState(false);
  const [objReverse, setObjectReveserse] = useState(null);

  useEffect(() => {
    const data = Object.assign([], rowsData).reverse();

    setObjectReveserse(data);
  }, [rowsData]);

  const startNewOrder = (customer) => {
    // TODO substituir por redux

    // dispatch(selectCustomerActions(customer));

    // localStorage.setItem("newOrderCustomerIndex", newOrderCustomerIndex)
    history.push(`/dados/pedido/cliente/${customer.id}`);
  };

  console.log("reverse", reverse);

  function funcReverse() {
    setReverse(!reverse);
  }

  return (
    <Container>
      <Table>
        <TitleTable>
          <Title>
            N° Pedido{" "}
            <img
              onClick={funcReverse}
              style={{
                marginLeft: 4,
              }}
              src={order}
              alt="order"
            />
          </Title>
          <Title>
            Cliente
            <img
              onClick={funcReverse}
              style={{
                marginLeft: 4,
              }}
              src={order}
              alt="order"
            />
          </Title>
          <Title>
            Data Negociação{" "}
            <img
              onClick={funcReverse}
              style={{
                marginLeft: 4,
              }}
              src={order}
              alt="order"
            />
          </Title>
          <Title>
            Status{" "}
            <img
              onClick={funcReverse}
              style={{
                marginLeft: 4,
              }}
              src={order}
              alt="order"
            />
          </Title>
          <Title>
            Solicitação Atendida{" "}
            <img
              onClick={funcReverse}
              style={{
                marginLeft: 4,
              }}
              src={order}
              alt="order"
            />
          </Title>
        </TitleTable>
        {reverse === false &&
          rowsData &&
          rowsData.map((item) => (
            <RowContainer>
              <RowTable>
                <RowItem width="100px" height="">
                  {item.codigo}
                </RowItem>
                <RowItem width="100px"> {item.clienteContato.nome}</RowItem>
                <RowItem width="100px">{item.data}</RowItem>
                <RowItem width="100px">{item.statusNegociacao}</RowItem>
                <RowItem width="100px">{item.tipoVenda}</RowItem>
              </RowTable>
            </RowContainer>
          ))}

        {reverse === true &&
          objReverse &&
          objReverse.map((item) => (
            <RowContainer>
              <RowTable>
                <RowItem width="100px" height="">
                  {item.codigo}
                </RowItem>
                <RowItem width="100px"> {item.clienteContato.nome}</RowItem>
                <RowItem width="100px">{item.data}</RowItem>
                <RowItem width="100px">{item.statusNegociacao}</RowItem>
                <RowItem width="100px">{item.tipoVenda}</RowItem>
              </RowTable>
            </RowContainer>
          ))}
      </Table>
    </Container>
  );
}

export default TableList;
