import {
  Container,
  ContainerLogo,
  Logo,
  SvgButton,
  ContainerImage,
  ContainerText,
} from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deslogAction } from "../../reducers/config/actions";

import LogoImage from "../../assets/Logo.png";
import Home from "../../assets/home.svg";

function Header() {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  console.log(location.pathname);

  return (
    <Container>
      <ContainerImage onClick={() => history.push("/home")}>
        {location.pathname == "/" ? null : <SvgButton src={Home} />}
      </ContainerImage>
      <ContainerLogo>
        <Logo src={LogoImage} />
      </ContainerLogo>
      <ContainerText
        onClick={() => {
          const data = {
            success: false,
          };
          dispatch(deslogAction(data));
          localStorage.removeItem("persist:DATATEM");
          history.push("/");
        }}
      >
        {location.pathname == "/" ? null : "Sair"}
      </ContainerText>
    </Container>
  );
}

export default Header;
