import {
  PORTUGUES,
  ENGLISH,
  LOGIN,
  DESLOGAR,
  ERROR_REQUEST,
  SUCCESS_REQUEST,
  CLEAR_REQUEST,
} from "./types";

const INITIAL_STATE = {
  language: "pt",
  message: "",
  error: false,
  auth: {
    success: false,
  },
};

export default function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case PORTUGUES:
      return { ...state, language: "pt" };
    case ENGLISH:
      return { ...state, language: "en" };
    case LOGIN:
    case DESLOGAR:
      return { ...state, auth: payload };
    case ERROR_REQUEST:
      return { ...state, message: payload, error: true };
    case SUCCESS_REQUEST:
      return { ...state, message: payload, error: false };
    case CLEAR_REQUEST:
      return { ...state, message: "", error: false };
    default:
      return state;
  }
}
