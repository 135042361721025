import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Message() {
  const messageRequest = useSelector((state) => state.config.message);
  const errorRequest = useSelector((state) => state.config.error);

  useEffect(() => {
      
    const notifySuccess = () => toast.success(`${messageRequest}`);
    const notifyError = () => toast.error(`${messageRequest}`);

    if (errorRequest === true && messageRequest !== "") {
      notifyError();
    }

    if (errorRequest === false && messageRequest !== "") {
      notifySuccess();
    }
  }, [errorRequest, messageRequest]);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export default Message;
