import { useState } from "react";
import {
  Container,
  Header,
  Text,
  Row,
  ButtonSave,
  InfoCliente,
  ContainerInfo,
  Client,
  ClientInfo,
} from "./styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sendDataActions } from "../../reducers/newOrders/actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

function NewProduct() {
  const customer = useSelector((state) => state.newOrders.newOrder.customer);

  const dispatch = useDispatch();

  const history = useHistory();

  const [data, setData] = useState({
    tipoVenda: "",
    tipoEntrega: "",
    statusNegociacao: "",
    vendedor: "",
    contato: "",
    data: "",
    dataFechamento: "",
    peridiocidadeContrato: "",
  });

  function onSubmit(e) {
    e.preventDefault();

    if (
      data.tipoVenda !== "" &&
      data.tipoEntrega !== "" &&
      data.statusNegociacao !== "" &&
      data.vendedor !== "" &&
      data.contato !== "" &&
      data.data !== "" &&
      data.dataFechamento !== "" &&
      data.peridiocidadeContrato !== ""
    ) {
      dispatch(sendDataActions(data));
      history.push("/selecionar/produto");
      console.log("Dados_pedido", data);
    } else {
      alert("CAMPO VAZIO");
    }
  }

  return (
    <>
      <Container>
        <Header>
          <div style={{ display: "flex" }}>
            <ArrowBackIcon style={{ marginRight: 5 }} />
            <Text
              fontSize="20px"
              cursor="pointer"
              onClick={() => history.push("/novo/produto")}
            >
              Voltar
            </Text>
          </div>
          <Text fontSize="32px" cursor="unset">
            Dados pedido
          </Text>
        </Header>

        <Row>
          <InfoCliente>
            <ContainerInfo>
              <ClientInfo>Cliente:</ClientInfo>
              <Client>
                {customer?.nomeFantasia} | {customer?.codigo}
              </Client>
            </ContainerInfo>
          </InfoCliente>
        </Row>

        <form onSubmit={onSubmit} style={{ height: 400 }}>
          <Row>
            <FormControl
              variant="outlined"
              style={{ width: 442, height: 45 }}
              required={true}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Tipo de Venda
              </InputLabel>
              <Select
                native
                label="Tipo de Venda"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                value={data.tipoVenda}
                onChange={(e) =>
                  setData({ ...data, tipoVenda: e.target.value })
                }
                required={true}
              >
                <option aria-label="None" value="" />
                <option value={"Venda Direta"}>Venda Direta</option>
                <option value={"Venda Indireta"}>Venda Indireta</option>
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              style={{ width: 442, height: 45, marginLeft: 5 }}
              required={true}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Tipo de Entrega{"     "}
              </InputLabel>
              <Select
                native
                label="Tipo de Venda"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                value={data.tipoEntrega}
                onChange={(e) =>
                  setData({ ...data, tipoEntrega: e.target.value })
                }
                required={true}
              >
                <option aria-label="None" value="" />
                <option value={"Entrega  Direta"}>Entrega Direta</option>
                <option value={"Entrega Indireta"}>Entrega Indireta</option>
              </Select>
            </FormControl>
          </Row>

          <Row>
            <FormControl
              variant="outlined"
              style={{ width: 448, height: 45 }}
              required={true}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Status de negociação
              </InputLabel>
              <Select
                native
                required={true}
                label="Status de negociação"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                value={data.statusNegociacao}
                onChange={(e) =>
                  setData({ ...data, statusNegociacao: e.target.value })
                }
              >
                <option aria-label="None" value="" />
                <option value={"Em andamento"}>Em andamento</option>
                <option value={"Concluido"}>Concluido</option>
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              required={true}
              style={{ width: 442, height: 45, marginLeft: 5 }}
              label="Vendedor"
              value={data.vendedor}
              onChange={(e) => setData({ ...data, vendedor: e.target.value })}
            />
          </Row>

          <Row>
            <FormControl
              variant="outlined"
              style={{ width: 448, height: 45 }}
              required={true}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Contato
              </InputLabel>
              <Select
                native
                label="Contato"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                value={data.contato}
                onChange={(e) => setData({ ...data, contato: e.target.value })}
              >
                <option aria-label="None" value="" />
                <option value={"Contato 1"}>Contato 1</option>
                <option value={"Contato 2"}>Contato 2</option>
              </Select>
            </FormControl>
            <TextField
              id="date"
              value={data.data}
              onChange={(e) => setData({ ...data, data: e.target.value })}
              style={{ margin: "3px", padding: 5 }}
              label="Data da Proposta"
              required={true}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              value={data.dataFechamento}
              onChange={(e) =>
                setData({ ...data, dataFechamento: e.target.value })
              }
              id="date"
              style={{ margin: "3px", padding: 5 }}
              required={true}
              label="Data do Fechamento"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Row>

          <Row>
            <FormControl
              variant="outlined"
              style={{ width: 442, height: 45, marginLeft: 1, marginTop: -12 }}
              required={true}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Periocidade do Contrato{" "}
              </InputLabel>
              <Select
                native
                label="Periocidade do Contrato"
                inputProps={{
                  name: "age",
                  id: "outlined-age-native-simple",
                }}
                required={true}
                value={data.peridiocidadeContrato}
                onChange={(e) =>
                  setData({
                    ...data,
                    peridiocidadeContrato: e.target.value,
                  })
                }
              >
                <option aria-label="None" value="" />
                <option value={"Entrega  Direta"}>Entrega Direta</option>
                <option value={"Entrega Indireta"}>Entrega Indireta</option>
              </Select>
            </FormControl>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <ButtonSave type="button" background="#C0C0C0" color="gray">
              Cancelar Pedido
            </ButtonSave>
            <ButtonSave type="submit" background="#DFAB0E" color="white">
              Selecionar Produtos
            </ButtonSave>
          </Row>
        </form>
      </Container>
    </>
  );
}

export default NewProduct;
