import styled from 'styled-components';

export const LineMain = styled.div`
    display:flex;
    background-color:#DEAA1E;   
    height:2px;
    width:126px;
    justify-content:center;
    margin-top:8px;
    margin-bottom:10px;
`;

export function Line(){
    return(        
        <LineMain/>
        )
}