import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Home,
  Login,
  Client,
  Product,
  NotFound,
  RegisterClient,
  EditClient,
  NewProduct,
  OrderData,
  SelectProduct,
  DetailedOrder,
  Reports,
  InfoTable,
} from "./screens/index";

import { Header } from "./components/index";
import Message from "./components/Message/Message";

function PrivateRoute({ component: Component, ...rest }) {
  const successLogin = useSelector((state) => state.config.auth.success);

  return (
    <Route
      {...rest}
      render={(props) =>
        successLogin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

function Routes() {
  return (
    <Router>
      <Header />
      <Message />
      <Switch>
        <Route path="/" exact component={Login} />
        {/* <PrivateRoute path="/main" exact component={Main} /> */}
        <PrivateRoute path="/home" exact component={Home} />
        <PrivateRoute path="/clientes" exact component={Client} />
        <PrivateRoute path="/produtos" exact component={Product} />
        <PrivateRoute path="/novo/produto" exact component={NewProduct} />
        <PrivateRoute
          path="/dados/pedido/cliente/:id"
          exact
          component={OrderData}
        />
        <PrivateRoute
          path="/selecionar/produto"
          exact
          component={SelectProduct}
        />
        <PrivateRoute path="/detalhe/pedido" exact component={DetailedOrder} />
        <PrivateRoute
          path="/cadastrar/cliente"
          exact
          component={RegisterClient}
        />
        <PrivateRoute path="/editar/cliente/:id" exact component={EditClient} />
        <PrivateRoute path="/relatorios" exact component={Reports} />
        <PrivateRoute
          path="/relatorios/detalhes/mes/:mes/:ano/:vendedor"
          exact
          component={InfoTable}
        />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </Router>
  );
}

export default Routes;
