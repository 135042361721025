import {
  Container,
  Header,
  ContainerCharts,
  Return,
  Text,
  TitleContainer,
  Title,
  Image,
} from "./styles";
import Charts from "./Charts";

import setaDireita from "../../assets/setaEsquerda.svg";

export default function Reports() {
  return (
    <Container>
      <Header>
        <Return>
          <Image src={setaDireita} alt="setaDireita" />
          <Text>Voltar</Text>
        </Return>
        <TitleContainer>
          <Title>CHIPS VENDIDOS</Title>
        </TitleContainer>
      </Header>
      <ContainerCharts>
        <Charts />
      </ContainerCharts>
    </Container>
  );
}
