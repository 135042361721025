import styled from "styled-components";
import moment from "moment";
import Edit from "../../../../assets/writing.svg";


function ContactList({ item, handleOpen,setEdit,setFormEdit }) {
  console.log("ARRAY", item);


  function Click(){
    handleOpen()
    setEdit(true)
    setFormEdit(item)
  }

  return (
    <>
      <div
        style={{
          marginTop: 23,
          display: "flex",
          justifyContent: "space-around",
          width: 230,
        }}
      ></div>

      <ContainerList>
        <Item style={{ cursor:"pointer",width:40 }} onClick={Click}>
          <img src={Edit} alt="edit" width="28" height="28" />
        </Item>
        <Item>{item.nome}</Item>
        <Item>{item.telefone}</Item>
        <Item>{item.email}</Item>
        <Item>{item.cidade}</Item>
        <Item>{item.cargo}</Item>
        {/* <ContactEdit/> */}
      </ContainerList>
    </>
  );
}

export default ContactList;

export const Container = styled.div``;

export const ContainerList = styled.div`
  width: 1051px;
  height: 49px;
  border-radius: 2px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  border-right: 0.7px solid gray;
  padding: 7.1px;
  width: 400px;
`;

export const Text = styled.p`
  margin-left: 5px;
`;
