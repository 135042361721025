import api from "../../config/api";
import { COMPLETE_FIELDS, SEND_FORM, EDIT_MODE_SUCESS } from "./types";

export const completeFiled = (boolean) => ({
  type: COMPLETE_FIELDS,
  payload: boolean,
});

export const sendFormComplete = (client, token) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return async (dispatch) => {
    try {
      await api.post("/api/v1/cliente", client, config);
    } catch (error) {
      console.log("sendClient_error", error);
      alert("ERROR!");
    }
  };
};

export const sendFormAction = (data) => ({
  type: SEND_FORM,
  payload: data,
});

export const EditModeAction = (id, token, history) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return async (dispatch) => {
    try {
      const { data } = await api.get(`/api/v1/cliente/${id}`, config);
      dispatch(EditModeSuccess(data));
      console.log("REGISTER_EDIT", data);
      history.push(`/editar/cliente/${id}`);
    } catch (error) {
      console.log("ERROR_EDIT", error);
    }
  };
};

export const EditModeSuccess = (data) => ({
  type: EDIT_MODE_SUCESS,
  payload: data,
});

export const EditCompleteAction = (data, id, token, history) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return async (dispatch) => {
    try {
      await api.put(`/api/v1/cliente/${id}`, data, config);
      history.push("/clientes");
    } catch (error) {
      console.log("erro_edit", error);
    }
  };
};
