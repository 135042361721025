import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 834px;
`;

export const Text = styled.p`
  font-size: ${(props) => props.fontSize};
  font-family: Roboto;
  font-weight: 2px;
  cursor: ${(props) => props.cursor};
`;

export const ContainerFilter = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const ButtonSave = styled.button`
  background-color: #dfab0e;
  width: 142px;
  height: 45px;
  color: white;
  border: 2px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
`;

//Table

export const Table = styled.div`
  width: 800px;
  margin-top: 20px;
`;
export const TitleTable = styled.div`
  display: flex;
`;
export const Title = styled.p`
  margin: auto;
  color: #86888e;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
`;

export const RowTable = styled.div`
  display: flex;
  width: 665px;
  height: 45px;

  background: #f4f4f4;
  border-radius: 6px;
  margin-left: 15px;
`;

export const RowItem = styled.p`
  margin: auto;
  text-transform: uppercase;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: ${(props) => props.width};
`;

export const ButtonAdd = styled.button`
  background-color: #00973c;
  width: 93px;
  height: 45px;
  border-radius: 6px;
  border: 0px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;
  margin-left: 25px;
  outline: none;

  :hover {
    opacity: 0.9;
  }
`;
export const SvgButton = styled.img`
  /* margin-right: 17px; */
`;

export const RowContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;




export const Row = styled.div`
  margin-top: 12px;
  display: flex;
`;

export const ContainerTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;



export const TableHeader = styled.div`
  margin-top: 5px;
  background-color: #86888e;
  width: 834px;
  height: 37px;
  border-radius: 6px;
  display: flex;
`;

export const HeaderItem = styled.div`
  width: ${(props) => props.width};
  margin-top: 8.5px;
  color: white;
  font-size: ${(props) => props.fontSize};
  font-family: "Open Sans";
`;

export const TableRow = styled.div`
  margin-top: 5px;
  background-color: #f4f4f4;
  width: 834px;
  height: 45px;
  border-radius: 6px;
  display: flex;
  cursor:pointer;
`;

