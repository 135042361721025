import styled from "styled-components";

export const Container = styled.div`
  height: 79.6vh;
`;
export const Header = styled.div`
  height: 13%;
  display: flex;
`;
export const ContainerCharts = styled.div`
  height: 100%;
  width: 90%;
  margin: auto;
  /* border: 1px solid #0b2138; */
`;

export const Return = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  color: #0b2138;
  cursor: pointer;
  margin-left: 10px;
`;

export const Text = styled.h3``;
export const Title = styled.h4``;
export const TitleContainer = styled.div`
  margin: auto;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  color: #0b2138;
`;

export const Image = styled.img`
  margin: 5px;
`;
