import { useEffect } from "react";
import { Row, Container } from "../Address/styles";
import cepIcon from "../../../../assets/cepIcon.svg";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { useStatePersist } from "use-state-persist";
import { useDispatch, useSelector } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";
import Checkbox from "@material-ui/core/Checkbox";

function Address({ setAddDeliveryComplete }) {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.registerClient.form);
  const [checkbox, setCheckbox] = useStatePersist("@checkbox", false);

  const {
    enderecoCEP,
    enderecoCidade,
    enderecoBairro,
    enderecoUf,
    endereco,
    enderecoNumero,
    enderecoComplemento,
  } = address;

  const [addressDelivery, setAddressDelivery] = useStatePersist(
    "@addressDeliveryFields",
    {
      enderecoEntrega: "",
      enderecoEntregaCEP: "",
      enderecoEntregaNumero: "",
      enderecoEntregaComplemento: "",
      enderecoEntregaBairro: "",
      enderecoEntregaCidade: "",
    }
  );

  useEffect(() => {
    if (checkbox === true) {
      const getAddressMain = {
        enderecoEntrega: endereco,
        enderecoEntregaCEP: enderecoCEP,
        enderecoEntregaNumero: enderecoNumero,
        enderecoEntregaComplemento: enderecoComplemento,
        enderecoEntregaBairro: enderecoBairro,
        enderecoEntregaCidade: enderecoCidade,
        enderecoEntregaUf: enderecoUf,
      };
      setAddressDelivery(getAddressMain);
    }

    if (checkbox === false) {
      setAddressDelivery({
        enderecoEntrega: "",
        enderecoEntregaCEP: "",
        enderecoEntregaNumero: "",
        enderecoEntregaComplemento: "",
        enderecoEntregaBairro: "",
        enderecoEntregaCidade: "",
      });
    }
  }, [checkbox]);

  function searchCep(value) {
    setAddressDelivery({
      ...addressDelivery,
      enderecoEntrega: "",
      enderecoEntregaCEP: value,
      enderecoEntregaNumero: "",
      enderecoEntregaComplemento: "",
      enderecoEntregaBairro: "",
      enderecoEntregaCidade: "",
    });

    if (value.length >= 8) {
      console.log(value)
      axios
        .get(`https://viacep.com.br/ws/${value}/json`)
        .then((res) => {
          let zipCode = res.data.cep;
          let address = res.data.logradouro;
          let neighborhood = res.data.bairro;
          let city = res.data.localidade;
          let uf = res.data.uf;

          setAddressDelivery({
            ...addressDelivery,
            enderecoEntrega: address,
            enderecoEntregaCEP: zipCode,
            enderecoEntregaBairro: neighborhood,
            enderecoEntregaCidade: city,
            enderecoEntregaUf: uf,
          });
        })
        .catch((error) => console.log(error));
    }
  }

  useEffect(() => {
    if (
      addressDelivery.enderecoEntregaCEP !== "" &&
      addressDelivery.enderecoEntregaCidade !== "" &&
      addressDelivery.enderecoEntregaBairro !== "" &&
      addressDelivery.enderecoEntregaUf !== "" &&
      addressDelivery.enderecoEntrega !== "" &&
      addressDelivery.enderecoEntregaNumero !== "" &&
      addressDelivery.enderecoEntregaComplemento !== ""
    ) {
      setAddDeliveryComplete(true);
      dispatch(sendFormAction(addressDelivery));
    } else {
      setAddDeliveryComplete(false);
    }
  }, [addressDelivery, setAddDeliveryComplete, dispatch]);

  return (
    <Container>
      <p>
        <Checkbox
          defaultChecked={checkbox}
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
          value={checkbox}
          onChange={(event) => setCheckbox(!checkbox)}
        />{" "}
        Preencher com endereço principal
      </p>

      <Row name="row-1">
        <TextField
          variant="outlined"
          type="text"
          disabled={checkbox}
          name="enderecoEntregaCEP"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          label="CEP"
          value={addressDelivery.enderecoEntregaCEP}
          onChange={(event) => searchCep(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <img src={cepIcon} width="23" alt="cep" height="23" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="outlined"
          name="city"
          label="Cidade"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          type="text"
          disabled={checkbox}
          value={addressDelivery.enderecoEntregaCidade}
          onChange={(event) =>
            setAddressDelivery({
              ...addressDelivery,
              enderecoEntregaCidade: event.target.value,
            })
          }
        />

        <TextField
          variant="outlined"
          name="neighborhood"
          disabled={checkbox}
          label="Bairro"
          style={{ marginTop: 12, width: 260, margin: 6 }}
          value={addressDelivery.enderecoEntregaBairro}
          onChange={(event) =>
            setAddressDelivery({
              ...addressDelivery,
              enderecoEntregaBairro: event.target.value,
            })
          }
        />

        <TextField
          variant="outlined"
          name="uf"
          disabled={checkbox}
          label="UF"
          style={{ marginTop: 12, width: 80, margin: 6 }}
          value={addressDelivery.enderecoEntregaUf}
          onChange={(event) =>
            setAddressDelivery({
              ...addressDelivery,
              enderecoEntregaUf: event.target.value,
            })
          }
        />
      </Row>

      <Row name="row-2">
        <TextField
          variant="outlined"
          name="address"
          disabled={checkbox}
          value={addressDelivery.enderecoEntrega}
          onChange={(event) =>
            setAddressDelivery({
              ...addressDelivery,
              enderecoEntrega: event.target.value,
            })
          }
          label="Endereço"
          style={{ marginTop: 12, width: 360, margin: 6 }}
        />

        <TextField
          variant="outlined"
          label="Nº"
          name="number"
          disabled={checkbox}
          style={{ marginTop: 12, width: 90, margin: 6 }}
          value={addressDelivery.enderecoEntregaNumero}
          onChange={(event) =>
            setAddressDelivery({
              ...addressDelivery,
              enderecoEntregaNumero: event.target.value,
            })
          }
        />

        <TextField
          variant="outlined"
          disabled={checkbox}
          label="Complemento"
          name="complement"
          style={{ marginTop: 12, width: 360, margin: 6 }}
          value={addressDelivery.enderecoEntregaComplemento}
          onChange={(event) =>
            setAddressDelivery({
              ...addressDelivery,
              enderecoEntregaComplemento: event.target.value,
            })
          }
        />
      </Row>
    </Container>
  );
}

export default Address;
