import styled from "styled-components";

export const ButtonAdd = styled.button`
  background-color: #00973c;
  width: 221px;
  height: 60px;
  border-radius: 6px;
  border: 0px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const SvgButton = styled.img`
  margin-right: 17px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  justify-content: space-around;
  margin: auto;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 60%;
`;

export const TextRegister = styled.h2`
  color: #0b2138;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
`;

export const ButtonSave = styled.button`
  background-color: #dfab0e;
  width: 142px;
  height: 45px;
  color: white;
  border: 2px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 20px;
`;

export const Paragraf = styled.p`
  color: red;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
`;

export const ContainerParagraf = styled.div`
  width: 270px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  margin: 10;
`;

export const Row = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
`;

export const ContainerTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled.div`
  width: 800px;
`;

export const TableHeader = styled.div`
  margin-top: 5px;
  background-color: #86888e;
  width: 834px;
  height: 37px;
  border-radius: 6px;
  display: flex;
`;

export const HeaderItem = styled.div`
  width: ${(props) => props.width};
  margin-top: 8.5px;
  color: white;
  font-size: ${(props) => props.fontSize};
  font-family: "Open Sans";
`;

export const TableRow = styled.div`
  margin-top: 5px;
  background-color: #f4f4f4;
  width: 834px;
  height: 45px;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
`;

export const RowItem = styled.div`
  width: ${(props) => props.width};
  margin: 12px;
  color: black;
  font-size: ${(props) => props.fontSize};
  font-family: "Open Sans";
`;
