import {
  SELECT_CUSTOMER,
  SEND_PRODUCTS,
  SAVE_ORDERS,
  SEND_DATA_REQUEST,
} from "./types";

export const selectCustomerActions = (customer) => ({
  type: SELECT_CUSTOMER,
  payload: customer,
});

export const sendProductsActions = (customer) => ({
  type: SEND_PRODUCTS,
  payload: customer,
});

export const saveOrdersActions = (data) => ({
  type: SAVE_ORDERS,
  payload: data,
});

export const sendDataActions = (data) => ({
  type: SEND_DATA_REQUEST,
  payload: data,
});
