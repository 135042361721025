import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";
import { Row } from "./styles";
import TextField from "@material-ui/core/TextField";
import { useStatePersist as useState } from "use-state-persist";

function General({ setInvoice }) {
  const dispatch = useDispatch();

  const stateFormEdit = useSelector(
    (state) => state.registerClient.formEdit[0]
  );

  console.log("INVOICE", stateFormEdit);

  const [invoice, setInvoiceField] = useState("@invoiceFields", {
    emailNotaFiscal: "",
  });

  useEffect(() => {
    const { emailNotaFiscal } = stateFormEdit;
    setInvoiceField({
      emailNotaFiscal,
    });
  }, [stateFormEdit]);

  useEffect(() => {
    const { emailNotaFiscal } = invoice;
    if (emailNotaFiscal !== "") {
      setInvoice(true);
      dispatch(sendFormAction(emailNotaFiscal));
    } else {
      setInvoice(false);
      dispatch(sendFormAction({}));
    }
  }, [invoice, setInvoice, dispatch]);

  return (
    <form>
      <Row>
        <TextField
          name="email"
          value={invoice.emailNotaFiscal}
          label="E-mail"
          variant="outlined"
          type="text"
          style={{ marginTop: 12, width: 360, margin: 6 }}
          onChange={(event) =>
            setInvoiceField({ ...invoice, emailNotaFiscal: event.target.value })
          }
        />
      </Row>
    </form>
  );
}

export default General;
