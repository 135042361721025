import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import config from "./config/index";
import registerClient from "./registerClient/index";
import newOrders from "./newOrders/index";
import reports from "./reports/index";

export default combineReducers({
  config,
  registerClient,
  newOrders,
  reports,
  // form: reduxFormReducer,
});
