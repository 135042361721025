import styled from "styled-components";

export const Container = styled.div`
  width: 1024px;
  height: 212px;
  justify-content: center;
  align-items: center;
`;

export const ContainerSelect = styled.div`
  border: 1px dashed gray;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const Image = styled.img``;

export const ButtonSelect = styled.button`
  width: 269px;
  height: 43px;
  background-color: white;
  border: 1px solid gray;
`;

export const ButtonSave = styled.button`
  background-color: #aeaeae;
  width: 93px;
  height: 33px;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  margin:10px;
`;

// Document List

export const ContainerList = styled.div``;
export const Text = styled.p``;
