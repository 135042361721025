import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
`;

export const Text = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: 27px;
  margin-top: 12px;
`;

export const Description = styled.h3`
  margin-top: 7px;
  font-family: "Roboto", sans-serif;
  color: gray;
  font-size: 18px;
  margin-bottom: 3px;
`;

export const ContainerCard = styled.div`
  width: 442px;
  height: 120px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;

  :hover {
    border: 1px solid #dfab0e;
    background-color: #fffdf5;
  }
`;

export const Icone = styled.img`
  margin-left: 22px;
`;

export const ContainerTextCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const TextCard = styled.h3`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  margin-left: 2px;
`;

export const DescriptionCard = styled.p`
  font-family: "Roboto", sans-serif;
  color: gray;
  font-size: 15px;
  margin-top: 3px;
`;
