import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  sendProductsActions,
  saveOrdersActions,
} from "../../reducers/newOrders/actions";
import { useHistory } from "react-router-dom";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    // margin: "auto",
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function SimpleModal({
  open,
  setOpen,
  client,
  selectedProducts,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const customer = useSelector((state) => state.newOrders.newOrder.customer);

  const newOrder = useSelector((state) => state.newOrders.newOrder);

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  function confirm() {
    dispatch(saveOrdersActions(newOrder));
    history.push("/produtos");
  }

  const body = (
    <div
      style={{
        width: "400px",
        maxWidth: "100vw",
        maxHeight: "100%",
        position: "fixed",
        top: "50%",
        left: "32%",
        right: "32%",
        transform: "translate(0, -50%)",
        overflowY: "auto",
      }}
      className={classes.paper}
    >
      <Container>
        <TextMain>Você esta lançando um pedido para:</TextMain>

        <ClientInfo>Cliente: {customer?.nomeFantasia}</ClientInfo>

        <ContainerButton>
          <Button
            background="white"
            color="black"
            borderColor="black"
            width="220px"
            height="60px"
            onClick={() => handleClose()}
          >
            Voltar
          </Button>

          <Button
            background="#53993D"
            color="white"
            borderColor="#53993D"
            width="220px"
            height="60px"
            onClick={() => confirm()}
          >
            Confirmar
          </Button>
        </ContainerButton>
      </Container>
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

const Container = styled.div`
  background-color: white;
  padding: 5px;
  margin: auto;
`;
const ContainerButton = styled.div`
  display: flex;
`;
const TextMain = styled.p`
  width: 100%;
  height: 28px;
  padding: 5px;
  margin-bottom: 15px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #0b2138;
`;
const ClientInfo = styled.p`
  /* width: 80.45px; */
  height: 30px;
  margin-bottom: 15px;

  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;

  color: #86888e;
`;
const Button = styled.button`
  /* border:none; */
  border-radius: 6px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-color: ${(props) => props.borderColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 5px;
  cursor: pointer;
  border: 0.8px solid black;
`;
