import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";
import { Row } from "./styles";
import TextField from "@material-ui/core/TextField";
import { useStatePersist as useState } from "use-state-persist";

function General({ setInvoice }) {
  const dispatch = useDispatch();

  const [invoice, setInvoiceField] = useState("@invoiceFields", {
    emailNotaFiscal: "",
  });

  useEffect(() => {
    const { emailNotaFiscal } = invoice;
    if (emailNotaFiscal !== "") {
      setInvoice(true);
      dispatch(sendFormAction(invoice));
    } else {
      setInvoice(false);
      dispatch(sendFormAction({}));
    }
  }, [invoice, setInvoice, dispatch]);

  return (
    <form>
      <Row>
        <TextField
          name="email"
          value={invoice.emailNotaFiscal}
          label="E-mail"
          variant="outlined"
          type="email"
          style={{ marginTop: 12, width: 360, margin: 6 }}
          onChange={(event) =>
            setInvoiceField({ ...invoice, emailNotaFiscal: event.target.value })
          }
        />
      </Row>
    </form>
  );
}

export default General;
