import ClipLoader from "react-spinners/ClipLoader";

export default function Loading({ loading }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
      }}
    >
      <ClipLoader
        color={"gray"}
        loading={loading}
        style={{
          display: "block",
          margin: " 0 auto",
          border: "red",
        }}
        size={35}
      />
    </div>
  );
}
