import { useState } from "react";
import {
  ButtonRegister,
  ContainerLabels,
  Container,
  InputSearch,
  SvgButton,
} from "./styles";
import Croos from "../../assets/croos.svg";
import TableCliente from "./Table/TableClient";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "redux-form";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import searchIcon from "../../assets/search.svg";

function Client() {
  const [search, setSearch] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  function onClick() {
    localStorage.removeItem("@useStatePerist:@checkbox");
    localStorage.removeItem("@useStatePerist:@generalFields");
    localStorage.removeItem("@useStatePerist:@arrayObservation");
    localStorage.removeItem("@useStatePerist:@arrayDocuments");
    localStorage.removeItem("@useStatePerist:@arrayContact");
    localStorage.removeItem("@useStatePerist:@invoiceFields");
    localStorage.removeItem("@useStatePerist:@addressFields");
    localStorage.removeItem("@useStatePerist:@addressDeliveryFields");
    localStorage.removeItem("@useStatePerist:@contactFields");
    localStorage.removeItem("@useStatePerist:@generalFields");
    localStorage.removeItem("@useStatePerist:@documentsFields");
    localStorage.removeItem("@useStatePerist:@observationFields");
    localStorage.removeItem("@useStatePerist:@documentPerson");

    history.push("cadastrar/cliente");
  }

  return (
    <Container>
      <ContainerLabels>
        {/* <InputSearch
          placeholder="Pesquisar  um cliente"
        /> */}
        <TextField
          id="input-with-icon-textfield"
          variant="outlined"
          style={{
            width: "442px",
            height: "60px",
            borderRadius: "6px",
            marginRight: "10px",
            fontSize: " 25px",
          }}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Pesquisar um cliente"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="search" />
              </InputAdornment>
            ),
          }}
        />
        <ButtonRegister onClick={onClick}>
          <SvgButton src={Croos} alt="cruz" />
          Cadastrar novo cliente
        </ButtonRegister>
      </ContainerLabels>

      <TableCliente search={search} />
    </Container>
  );
}

export default Client;
