import { useEffect, useState, useRef } from "react";
import { getDataAction } from "../../reducers/reports/index";
import { useSelector, useDispatch } from "react-redux";
import { Chart } from "react-google-charts";
import { useHistory } from "react-router-dom";
import { getInfoBar } from "../../reducers/reports/index";

const d = new Date();
const a = new Date();
a.setMonth(d.getMonth() - 1);

const months = [
  {
    year: 2020,
    month: 10,
  },
  {
    year: 2020,
    month: 11,
  },
];

export default function Charts() {
  const history = useHistory();
  const { token } = useSelector((state) => state.config.auth);
  const { data } = useSelector((state) => state.reports);
  const [uniqueMonthArray, setUniqueMonthArray] = useState([]);
  const [resultTotal, setResultTotal] = useState([]);
  const [columns, setColumns] = useState();
  const [display, setDisplay] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const resultTotalFiltered = resultTotal[0];
    const removeValue = resultTotalFiltered?.splice(1);
    setColumns(removeValue);
  }, [resultTotal]);

  useEffect(() => {
    const get = () => dispatch(getDataAction(token, months));
    get();
  }, [token]);

  useEffect(() => {
    const uniqueMonths = data
      ?.map((item) => item?.mes?.toString())
      ?.filter((value, index, self) => self?.indexOf(value) === index);
    const resultColumns = [];

    const result =
      data &&
      data?.reduce(function (r, a) {
        r[a?.vendedor] = r[a?.vendedor] || [];
        r[a?.vendedor]?.push(a);
        return r;
      }, Object.create(null));

    result !== null &&
      Object.keys(result).map((e) => {
        const temp = [];
        temp.push(e);

        result[e]?.map((j) => {
          temp.push(j.quantidade);
        });

        if (uniqueMonthArray && result) {
          if (
            uniqueMonthArray?.length !== (result[e] ? result[e]?.length : 0)
          ) {
            for (let i = result[e].length; i < uniqueMonthArray.length; i++) {
              temp.push(0);
            }
          }
        }

        resultColumns.push(temp);
      });
    resultColumns.unshift(["Vendedores"].concat(uniqueMonthArray));

    setResultTotal(resultColumns);
    setUniqueMonthArray(uniqueMonths);
  }, [data]);

  useEffect(() => {
    console.log("resultTotal", resultTotal);
    if (resultTotal.length > 1) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [resultTotal]);

  function getInfo(data) {
    const year = months[0].year;
    const name = data.result[0];

    if (data.column === 1) {
      const month = columns[0];

      dispatch(getInfoBar(token, month, year, name));
      history.push(`/relatorios/detalhes/mes/${month}/${year}/${name}`);
    } else {
      const month = columns[data.column - 1];

      dispatch(getInfoBar(token, month, year, name));
      history.push(`/relatorios/detalhes/mes/${month}/${year}/${name}`);
    }
  }

  return (
    <>
      {display && (
        <Chart
          width={null}
          height={null}
          chartType="Bar"
          loader={<div>Loading Chart</div>}
          data={resultTotal}
          options={{
            colors: ["#6E88A1", "#7CB4EC"],
          }}
          chartEvents={[
            {
              eventName: "select",
              callback({ chartWrapper }) {
                const selectedId = chartWrapper.getChart().getSelection();
                if (selectedId.length) {
                  const result = resultTotal[selectedId[0].row + 1];
                  const info = {
                    column: selectedId[0].column,
                    result,
                  };
                  getInfo(info);
                }
              },
            },
          ]}
        />
      )}
    </>
  );
}
