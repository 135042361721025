import { useState, useEffect } from "react";
import { Container, Header, Text, ContainerFilter, ButtonSave } from "./styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../../config/api";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import setaEsquerda from "../../assets/setaEsquerda.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import TableList from "./TableList";

const initialOptions = [
  {
    fantasyName: "Nubank",
    socialName: "Nubank",
    document: "111111",
    code: "1010",
  },
  {
    fantasyName: "Intermedium",
    socialName: "Banco Inter",
    document: "232323",
    code: "9910",
  },
  {
    fantasyName: "Itau SA",
    socialName: "Itau",
    document: "33333323",
    code: "5546",
  },
];

function NewProduct() {
  const { t } = useTranslation();
  const history = useHistory();
  const [filteredOptions, setFilteredOptions] = useState(initialOptions);
  const [searchOption, setSearchOption] = useState("nomeFantasia");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const token = useSelector((state) => state.config.auth.token);
  const LIMIT = 7;
  const [offset, setOfsset] = useState(0);
  let config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let sendInfo = {
    limit: LIMIT,
    offset: offset,
    [searchOption]: `${search}`,
  };

  useEffect(() => {
    setLoading(true);

    api
      .post(`/api/v1/cliente/busca`, sendInfo, config)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [offset, token, search]);

  useEffect(() => {
    setOfsset(0);
  }, [search]);

  return (
    <>
      <Container>
        <Header>
          <div style={{ display: "flex" }}>
            <ArrowBackIcon style={{ marginRight: 5 }} />
            <Text
              fontSize="20px"
              cursor="pointer"
              onClick={() => history.push("/produtos")}
            >
              {t("general.back")}
            </Text>
          </div>
          <Text fontSize="32px" cursor="unset">
            {t("customer_select.title")}
          </Text>
        </Header>

        <ContainerFilter>
          <FormControl variant="outlined" style={{ width: 252, height: 45 }}>
            <Select native onChange={(e) => setSearchOption(e.target.value)}>
              <option value={""}>{t("Select.all")}</option>
              <option value={"nomeFantasia"}>{t("Select.fantasy_name")}</option>
              <option value={"razaoSocial"}>{t("Select.social_name")}</option>
              <option value={"cnpjCpf"}>{t("Select.document")}</option>
              <option value={"codigo"}>{t("Select.code")}</option>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            style={{ width: 356, height: 45, marginLeft: 8 }}
            label={t("customer_select.customer_label")}
            onChange={(e) => setSearch(e.target.value)}
          />

          {/* <ButtonSave>{t("general.search")}</ButtonSave> */}
        </ContainerFilter>
      </Container>
      <TableList data={data} />
      <Pagination limit={LIMIT} offset={offset} setOffset={setOfsset} />
    </>
  );
}

export default NewProduct;
