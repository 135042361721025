import {
  Container,
  Table,
  TitleTable,
  Title,
  RowTable,
  RowItem,
  ButtonAdd,
  SvgButton,
  RowContainer,
} from "./styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Croos from "../../assets/croosWhite.svg";
import { selectCustomerActions } from "../../reducers/newOrders/actions";

function TableList({ data }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const startNewOrder = (customer) => {
    // TODO substituir por redux

    dispatch(selectCustomerActions(customer));

    // localStorage.setItem("newOrderCustomerIndex", newOrderCustomerIndex)
    history.push(`/dados/pedido/cliente/${customer.id}`);
  };

  return (
    <Container>
      <Table>
        <TitleTable>
          <Title>Iniciar pedido</Title>
          <Title>Nome fantasia</Title>
          <Title>Razão Social</Title>
          <Title>CNPJ/CPF</Title>
          <Title>Código do cliente</Title>
        </TitleTable>

        {data.map((customer, index) => {
          return (
            <RowContainer onClick={() => startNewOrder(customer)}>
              <ButtonAdd>
                <SvgButton src={Croos} />
              </ButtonAdd>
              <RowTable>
                <RowItem width="125px">{customer.nomeFantasia}</RowItem>
                <RowItem width>{customer.razaoSocial}</RowItem>
                <RowItem width="157px">{customer.cnpjCpf}</RowItem>
                <RowItem width>{customer.codigo}</RowItem>
              </RowTable>
            </RowContainer>
          );
        })}
      </Table>
    </Container>
  );
}

export default TableList;
