import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Text = styled.p`
  font-size: ${(props) => props.fontSize};
  font-family: Roboto;
  font-weight: 2px;
  cursor: ${(props) => props.cursor};
`;

export const Row = styled.div`
  display: flex;
  margin: 15px;
  margin-top: 20px;
`;

export const ButtonSave = styled.button`
  background-color: ${(props) => props.background};
  width: 402px;
  height: 60px;
  color: ${(props) => props.color};
  border: 2px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`;

//Table

export const Table = styled.div`
  width: 800px;
  margin-top: 20px;
`;
export const TitleTable = styled.div`
  display: flex;
`;
export const Title = styled.p`
  margin: auto;
  color: #86888e;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
`;

export const RowTable = styled.div`
  display: flex;
  width: 665px;
  height: 45px;

  background: #f4f4f4;
  border-radius: 6px;
  margin-left: 15px;
`;

export const RowItem = styled.p`
  margin: auto;
  text-transform: uppercase;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: ${(props) => props.width};
`;

export const ButtonAdd = styled.button`
  background-color: #00973c;
  width: 93px;
  height: 45px;
  border-radius: 6px;
  border: 0px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;
  margin-left: 25px;
  outline: none;

  :hover {
    opacity: 0.9;
  }
`;
export const SvgButton = styled.img`
  /* margin-right: 17px; */
`;

export const RowContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
export const InfoCliente = styled.div`
  width: 900px;
  height: 66px;

  background: rgba(236, 236, 236, 0.8);
  border-radius: 8px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  margin-top: 25px;
`;

export const Client = styled.p`
  /* Input Text */

  width: 196px;
  height: 22px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* blue-datatem */

  color: #0b2138;
`;
export const ClientInfo = styled.p`
  width: 59px;
  height: 22px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* gray-medium */

  color: #86888e;
  margin-left: 8px;
`;
