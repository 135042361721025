import { useEffect } from "react";
import { useSelector } from "react-redux";
import Routes from "./routes";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { i18n } = useTranslation();
  const language = useSelector((state) => state.config.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return (
    <>
      <Routes />
    </>
  );
}
