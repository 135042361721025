import styled from 'styled-components';

export const ButtonAdd = styled.button`
    background-color:#00973C;
    width:100px;
    height:33px;
    border-radius:6px;
    border:0px;
    color:white;
    display:flex;
    align-items:center;
    font-size:20px;
    justify-content:center;
    cursor:pointer;
`;

export const SvgButton = styled.img`
    color:white;
`;