import api from "../../config/api";
const GET_DATA = "GET_DATA";
const REPORTS_VIEW = "REPORTS_VIEW";

const INITIAL_STATE = {
  data: null,
  reportsView: null,
};

const reducer = {
  [GET_DATA]: (state, payload) => {
    return {
      ...state,
      data: payload,
    };
  },
  [REPORTS_VIEW]: (state, payload) => {
    return {
      ...state,
      reportsView: payload,
    };
  },
};

export default function strategyFloatingReducer(
  state = INITIAL_STATE,
  { type, payload }
) {
  if (typeof reducer[type] === "function") {
    return reducer[type](state, payload);
  }
  return state;
}

export const getDataAction = (token, data) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let fuckingString = "";

  data.map((date) => {
    fuckingString =
      fuckingString + `filtroMesAno[]=${date.month},${date.year}&`;
  });

  return async (dispatch) => {
    try {
      const { data } = await api.get(
        `/api/v1/relatorio/vendas/totais?${fuckingString}`,
        config
      );

      dispatch({
        type: GET_DATA,
        payload: data,
      });
    } catch (error) {
      console.log("getDataAction", error);
    }
  };
};

export const getInfoBar = (token, month, year, name) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return async (dispatch) => {
    try {
      const { data } = await api.get(
        `/api/v1/relatorio/vendas/detalhes?filtroMesAno=${month},${year}&vendedorApelido=${name}`,
        config
      );

      dispatch({
        type: REPORTS_VIEW,
        payload: data,
      });

      console.log("getInfoBar", data);
    } catch (error) {
      console.log("getInfoBar", error);
    }
  };
};
