import { ContainerList, ContainerDate, Text } from "./styles";
import moment from "moment";
// import File from "../../../../file.svg";

function ObservationList({ data, observacao }) {
  return (
    <>
      <ContainerList>
        <ContainerDate>{moment().format("DD/MM/YYYY", data)}</ContainerDate>
        <Text>{observacao}</Text>
      </ContainerList>
    </>
  );
}

export default ObservationList;
