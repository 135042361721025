import Home from "./Home/Home";
import Main from "./Main/Main";
import Login from "./Login/Login";
import Client from "./Client/Client";
import Product from "./Product/Product";
import NotFound from "./NotFound/NotFound";
import RegisterClient from "./RegisterClient/RegisterClient";
import EditClient from "./EditClient/EditClient";
import NewProduct from "./NewProduct/NewProduct";
import OrderData from "./OrderData/OrderData";
import DetailedOrder from "./DetailedOrder/DetailedOrder";
import SelectProduct from "./SelectProductV/SelectProduct";
import Reports from "./Reports/Reports";
import InfoTable from "./Reports/InfoTable";

export {
  Home,
  Main,
  Login,
  Client,
  Product,
  NotFound,
  RegisterClient,
  EditClient,
  NewProduct,
  OrderData,
  SelectProduct,
  DetailedOrder,
  Reports,
  InfoTable,
};
