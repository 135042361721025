import styled from 'styled-components';


export const ContainerTable = styled.div`
    width:834px;
    height:352px;
    margin-top:50px;
    margin-bottom:200px;
`;

export const ContainerImage = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;

`

export const Image = styled.img`
    width:25px;
    height:25px;

`;