import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PersonIcon from "../../assets/personIcon.svg";
import Requests from "../../assets/requestIcon.svg";
import Repost from "../../assets/reportsIcon.svg";

import {
  Container,
  Description,
  Text,
  ContainerCard,
  Icone,
  TextCard,
  ContainerTextCard,
  DescriptionCard,
} from "./styles";

export default function Home() {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Home - Datatem";
  }, []);

  return (
    <Container>
      <Text>{t("Home.title")}</Text>

      <Description>{t("Home.description")}</Description>

      <ContainerCard onClick={() => history.push("/clientes")}>
        <Icone
          src={PersonIcon}
          alt="Clientes"
          style={{ strokeColor: "white" }}
        />
        <ContainerTextCard>
          <TextCard>{t("Home.Customers.title")}</TextCard>
          <DescriptionCard>{t("Home.Customers.description")}</DescriptionCard>
        </ContainerTextCard>
      </ContainerCard>

      <ContainerCard onClick={() => history.push("/produtos")}>
        <Icone src={Requests} alt="Pedidos" stroke="red" />
        <ContainerTextCard style={{ marginLeft: 24 }}>
          <TextCard>{t("Home.Requests.title")}</TextCard>
          <DescriptionCard>{t("Home.Requests.description")}</DescriptionCard>
        </ContainerTextCard>
      </ContainerCard>

      <ContainerCard onClick={() => history.push("/relatorios")}>
        <Icone src={Repost} alt="Relatórios" />
        <ContainerTextCard>
          <TextCard>{t("Home.Reports.title")}</TextCard>
          <DescriptionCard>{t("Home.Reports.description")}</DescriptionCard>
        </ContainerTextCard>
      </ContainerCard>
    </Container>
  );
}
