import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 1136px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

export const Text = styled.p`
  font-size: ${(props) => props.fontSize};
  font-family: Roboto;
  font-weight: 2px;
  cursor: ${(props) => props.cursor};
  margin-right: 4px;
`;

export const Row = styled.div`
  display: flex;
  margin: 15px;
  margin-top: 20px;
`;

export const Div = styled.span``;

export const CustomTR = styled.tr`
  background-color: ${(props) => (props.selected ? "blue" : "white")};
`;

export const ButtonSave = styled.button`
  background-color: ${(props) => props.background};
  width: 402px;
  height: 60px;
  color: ${(props) => props.color};
  border: 2px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  border-radius: 5px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`;

//Table

export const Table = styled.div`
  width: 800px;
  margin-top: 20px;
`;
export const TitleTable = styled.div`
  display: flex;
`;
export const Title = styled.p`
  margin: auto;
  color: #86888e;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
`;

export const RowTable = styled.div`
  display: flex;
  width: 665px;
  height: 45px;

  background: #f4f4f4;
  border-radius: 6px;
  margin-left: 15px;
`;

export const RowItem = styled.p`
  margin: auto;
  text-transform: uppercase;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: ${(props) => props.width};
`;

export const ButtonAdd = styled.button`
  background-color: #00973c;
  width: 93px;
  height: 45px;
  border-radius: 6px;
  border: 0px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  cursor: pointer;
  margin-left: 25px;
  outline: none;

  :hover {
    opacity: 0.9;
  }
`;
export const SvgButton = styled.img`
  /* margin-right: 17px; */
`;

export const RowContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
export const InfoCliente = styled.div`
  width: 800px;
  height: 66px;

  background: rgba(236, 236, 236, 0.8);
  border-radius: 8px;
`;

export const ContainerProduct = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid gray;
  border-radius: 4px;
  margin: 5px;
  margin-top: 12px;
`;

export const SelectedProducts = styled.div`
  display: flex;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.border};
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  margin: 5px;
  margin-top: 12px;
  font-family: Open Sans;

  cursor: pointer;
  outline: none;
`;

export const TableProduct = styled.div`
  width: 96%;
  height: 340px;
  margin: auto;
`;
export const TableProductHead = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  background: #86888e;
  border-radius: 2px;
  display: flex;
  color: white;
  margin: auto;
  margin-top: ${(props) => props.mt};
`;
export const TableProductRow = styled.div`
  background: ${(props) => (props.selected === true ? "#ABCF9E" : "#F4F4F4")};
  color: ${(props) => (props.selected === true ? "white" : "black")};

  border-radius: 2px;
  width: 100%;
  height: 73px;
  padding: 5;
  /* margin: auto; */
  margin-top: 8px;
  display: flex;
  cursor: pointer;

  :focus {
    background: red;
  }
`;

export const TableSelectedRow = styled.div`
  background: ${(props) => (props.selected === true ? "#E77376" : "#F4f4f4")};
  color: ${(props) => (props.selected === true ? "white" : "black")};

  border-radius: 2px;
  width: 100%;
  height: 48px;
  margin: auto;
  margin-top: 8px;
  display: flex;
  cursor: pointer;

  :focus {
    background: red;
  }
`;

export const ContainerTableRow = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.height};
  overflow-x: hidden;
  /* overflow-y:hidden; */
  /* border: 1px solid red; */
`;

export const ItemTableRow = styled.div`
  margin: auto;
  font-family: "Open Sans";
  font-size: 12.8px;
  font-weight: 500;
  width: 100px;
`;

export const ItemTableHead = styled.div`
  /* margin: auto; */
  width: ${(props) => props.width};
  font-family: "Open Sans";
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextHead = styled.p`
  text-align: center;
  margin: 25px;
`;

export const ImageSvg = styled.img`
  /* width: 21px; */
  /* height: 20px;
  margin-right: 480px; */
  cursor: pointer;
`;

export const ContainerRows = styled.div`
  display: flex;
`;

export const ContainerSelecteds = styled.div`
  height: 60%;
  margin-bottom: 50px;
  /* border: 1px solid red; */
`;

export const Footer = styled.div`
  display: flex;
  /* margin: auto; */
  width: 100%;
`;

export const ItemFooter = styled.div`
  margin: auto;
  margin-bottom: 20px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #86888e;
`;

export const ItemAmount = styled.button`
  margin: 5px;
  width: 22px;
  cursor: pointer;
`;

export const ContainerInfo = styled.div`
  display: flex;
  margin-top: 25px;
`;

export const Client = styled.p`
  /* Input Text */

  width: 196px;
  height: 22px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* blue-datatem */

  color: #0b2138;
`;
export const ClientInfo = styled.p`
  width: 59px;
  height: 22px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* gray-medium */

  color: #86888e;
  margin-left: 8px;
`;

export const HeadTable = styled.div`
  display: flex;
  width: 122px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  padding: 5px;
`;

export const RowsTable = styled.div`
  width: 99%;
  height: 26px;
  margin-top: 10px;
  background: ${(props) =>
    props.selected ? "#ABCF9E" : "rgba(236, 236, 236, 0.8)"};
  border-radius: 3px;
  color: white;
  padding: 4px;
  display: flex;
  padding: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "white" : "gray")};
`;

export const ItemRow = styled.div`
  width: 125px;
  /* height: 100px; */
  margin: auto;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
`;

export const TextRow = styled.p`
  font-size: 12px;
  margin: auto;
`;
