import {
  SELECT_CUSTOMER,
  SEND_PRODUCTS,
  SAVE_ORDERS,
  SEND_DATA_REQUEST,
} from "./types";

const INITIAL_STATE = {
  orders: [],
  newOrder: {
    customer: null,
    products: null,
    dataRequest: null,
  },
};

const reducer = {
  [SELECT_CUSTOMER]: (state, payload) => {
    return {
      ...state,
      newOrder: { ...state.newOrder, customer: payload },
    };
  },
  [SEND_PRODUCTS]: (state, payload) => {
    return {
      ...state,
      newOrder: {
        ...state.newOrder,
        products: payload,
      },
    };
  },
  [SAVE_ORDERS]: (state, payload) => {
    return {
      ...state,
      orders: [...state.orders, payload],
      newOrder: {
        customer: null,
        products: null,
      },
    };
  },
  [SEND_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      newOrder: {
        ...state.newOrder,
        dataRequest: payload,
      },
    };
  },
};

export default function strategyFloatingReducer(
  state = INITIAL_STATE,
  { type, payload }
) {
  if (typeof reducer[type] === "function") {
    return reducer[type](state, payload);
  }
  return state;
}
