import {
  COMPLETE_FIELDS,
  SEND_FORM,
  EDIT_MODE_SUCESS,
  EDIT_COMPLETE,
} from "./types";

const INITIAL_STATE = {
  editMode: false,
  formEdit: null,
  form: {},
  allFieldsComplete: false,
  customerList: null,
};

const reducer = {
  [COMPLETE_FIELDS]: (state, payload) => {
    return {
      ...state,
      allFieldsComplete: payload,
    };
  },
  [SEND_FORM]: (state, data) => {
    return {
      ...state,
      form: { ...state.form, ...data },
    };
  },
  [EDIT_MODE_SUCESS]: (state, data) => {
    return {
      ...state,
      formEdit: [data],
    };
  },
  [EDIT_COMPLETE]: (state, payload) => {
    return {
      ...state,
      editMode: false,
      formEdit: {},
    };
  },
};

export default function strategyFloatingReducer(
  state = INITIAL_STATE,
  { type, payload }
) {
  if (typeof reducer[type] === "function") {
    return reducer[type](state, payload);
  }
  return state;
}
