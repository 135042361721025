import { useState, useEffect } from "react";
import {
  Container,
  Header,
  Text,
  ContainerFilter,
  ButtonSave,
  ContainerTable,
  RowItem,
  HeaderItem,
  TableHeader,
  Table,
  TableRow,
} from "./styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { useLocation } from "react-router-dom";
import seta from "../../assets/seta.svg";

const row = [
  {
    number: 98372,
    client: "AVIN ESTABELECIMENTOS",
    date: "04/01/1988",
    status: "A caminho",
    solicit: "Sim",
  },
];

function NewProduct() {
  const history = useHistory();
  const location = useLocation();

  console.log("location_state", location.state);
  return (
    <>
      <Container>
        <Header>
          <img src={seta} alt="seta" />
          <Text
            fontSize="20px"
            cursor="pointer"
            onClick={() => history.push("/produtos")}
          >
            Voltar
          </Text>
          <Text fontSize="32px" cursor="unset">
            Pedido detalhado
          </Text>
        </Header>
        <ContainerTable>
          <Table>
            <TableHeader>
              <HeaderItem width="127px">N° Pedido</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="254px">Cliente</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="157px">Data da negociação</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="176px">Status</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="120px" fontSize="14px">
                Solicitação atendita?
              </HeaderItem>
            </TableHeader>
            <TableRow>
              <RowItem width="127px">{location?.state?.customer?.code}</RowItem>
              <Divider orientation="vertical" flexItem />

              <RowItem width="254px">
                {location?.state?.customer?.socialName}
              </RowItem>
              <Divider orientation="vertical" flexItem />

              <RowItem width="157px">
                {location?.state?.dataRequest?.proposalDate}
              </RowItem>
              <Divider orientation="vertical" flexItem />

              <RowItem width="176px">
                {location?.state?.dataRequest?.negotiationStatus}
              </RowItem>
              <Divider orientation="vertical" flexItem />

              <RowItem width="120px" fontSize="14px">
                SIM
              </RowItem>
            </TableRow>
          </Table>
        </ContainerTable>

        <ContainerTable>
          <Table>
            <TableHeader>
              <HeaderItem width="127px">Código</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="254px">Descrição</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="157px">Qnt.</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="176px">Valor un.</HeaderItem>
              <Divider orientation="vertical" flexItem />

              <HeaderItem width="120px" fontSize="14px">
                Total
              </HeaderItem>
            </TableHeader>
            {location?.state?.products.map((item) => (
              <TableRow onClick={() => console.log(item)}>
                <RowItem width="127px">{item.code}</RowItem>
                <Divider orientation="vertical" flexItem />

                <RowItem width="254px">{item.description}</RowItem>
                <Divider orientation="vertical" flexItem />

                <RowItem width="157px">{1}</RowItem>
                <Divider orientation="vertical" flexItem />

                <RowItem width="176px">{item.value}</RowItem>
                <Divider orientation="vertical" flexItem />

                <RowItem width="120px" fontSize="14px">
                  {item.value}
                </RowItem>
              </TableRow>
            ))}
          </Table>
        </ContainerTable>
      </Container>
    </>
  );
}

export default NewProduct;
