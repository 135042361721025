import {
  PORTUGUES,
  ENGLISH,
  LOGIN,
  DESLOGAR,
  ERROR_REQUEST,
  SUCCESS_REQUEST,
  CLEAR_REQUEST
} from "./types";

export const actionLanguagePortugues = () => ({
  type: PORTUGUES,
});

export const actionLanguageEnglish = () => ({
  type: ENGLISH,
});

export const loginAction = (payload) => ({
  type: LOGIN,
  payload: payload,
});

export const deslogAction = (payload) => ({
  type: DESLOGAR,
  payload: payload,
});

export const errorAction = (payload) => ({
  type: ERROR_REQUEST,
  payload: payload,
});

export const successAction = (payload) => ({
  type: SUCCESS_REQUEST,
  payload: payload,
});

export const clearRequest = () => ({
  type:CLEAR_REQUEST
})