import { useState, useEffect } from "react";

import {
  ContainerInfo,
  TextRegister,
  ButtonSave,
  Paragraf,
  ContainerParagraf,
  Form,
  ContainerSwitchButton,
  ContainerDescriptions,
} from "./styles";
import SwitchButton from "../../components/SwitchButton/SwitchButton";
import { useSelector, useDispatch } from "react-redux";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  sendFormAction,
  sendFormCompleteAction,
  sendFormComplete,
  EditCompleteAction,
} from "../../reducers/registerClient/actions";
import { useHistory } from "react-router";
import { withStyles } from "@material-ui/core/styles";

import { TabsEdit } from "../../components/index";

import { Field, reduxForm, reset } from "redux-form";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import cadeado from "../../assets/cadeado.svg";
function RegisterClient() {
  const { allFieldsComplete } = useSelector((state) => state.registerClient);
  const [data, setData] = useState();
  const stateFormEdit = useSelector(
    (state) => state.registerClient.formEdit[0]
  );
  const form = useSelector((state) => state.registerClient.form);
  const token = useSelector((state) => state.config.auth.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [registerClientComplete, setRegisterClientComplete] = useState(false);
  const [registerClient, setRegisterClient] = useState({
    nomeFantasia: "",
    tipoCliente: "",
    segmento: "",
    tipoPessoa: "",
    marketing: false,
    marketingDatatem: false,
    ativo: false,
    prospect: false,
  });

  const segmento = {
    ramos_de_atividade: [
      "Alimentos e Bebidas",
      "Arte e Antiguidades",
      "Artigos Religiosos",
      "Assinaturas e Revistas",
      "Automóveis e Veículos",
      "Bebês e Cia",
      "Blu-Ray",
      "Brindes / Materiais Promocionais",
      "Brinquedos e Games",
      "Casa e Decoração",
      "CDs",
      "Colecionáveis",
      "Compras Coletivas",
      "Construção e Ferramentas",
      "Cosméticos e Perfumaria",
      "Cursos e Educação",
      "Discos de Vinil",
      "DVDs",
      "Eletrodomésticos",
      "Eletrônicos",
      "Emissoras de Rádio",
      "Emissoras de Televisão",
      "Empregos",
      "Empresas de Telemarketing",
      "Esporte e Lazer",
      "Fitas K7 Gravadas",
      "Flores, Cestas e Presentes",
      "Fotografia",
      "HD-DVD",
      "Igrejas / Templos / Instituições Religiosas",
      "Indústria, Comércio e Negócios",
      "Infláveis Promocionais",
      "Informática",
      "Ingressos",
      "Instrumentos Musicais",
      "Joalheria",
      "Lazer",
      "LD",
      "Livros",
      "MD",
      "Moda e Acessórios",
      "Motéis",
      "Música Digital",
      "Natal",
      "Negócios e Oportunidades",
      "Outros Serviços",
      "Outros Serviços de Avaliação",
      "Papelaria e Escritório",
      "Páscoa",
      "Pet Shop",
      "Saúde",
      "Serviço Advocaticios",
      "Serviço de Distribuição de Jornais / Revistas",
      "Serviços Administrativos",
      "Serviços Artísticos",
      "Serviços de Abatedouros / Matadouros",
      "Serviços de Aeroportos",
      "Serviços de Agências",
      "Serviços de Aluguel / Locação",
      "Serviços de Armazenagem",
      "Serviços de Assessorias",
      "Serviços de Assistência Técnica / Instalações ",
      "Serviços de Associações",
      "Serviços de Bancos de Sangue",
      "Serviços de Bibliotecas",
      "Serviços de Cartórios",
      "Serviços de Casas Lotéricas",
      "Serviços de Confecções",
      "Serviços de Consórcios",
      "Serviços de Consultorias",
      "Serviços de Cooperativas",
      "Serviços de Despachante",
      "Serviços de Engenharia",
      "Serviços de Estacionamentos",
      "Serviços de Estaleiros",
      "Serviços de Exportação / Importação",
      "Serviços de Geólogos",
      "Serviços de joalheiros",
      "Serviços de Leiloeiros",
      "Serviços de limpeza",
      "Serviços de Loja de Conveniência",
      "Serviços de Mão de Obra",
      "Serviços de Órgão Públicos",
      "Serviços de Pesquisas",
      "Serviços de Portos",
      "Serviços de Saúde / Bem Estar",
      "Serviços de Seguradoras",
      "Serviços de Segurança",
      "Serviços de Sinalização",
      "Serviços de Sindicatos / Federações",
      "Serviços de Traduções",
      "Serviços de Transporte",
      "Serviços de Utilidade Pública",
      "Serviços em Agricultura / Pecuária / Piscicultura",
      "Serviços em Alimentação",
      "Serviços em Arte",
      "Serviços em Cine / Foto / Som",
      "Serviços em Comunicação",
      "Serviços em Construção",
      "Serviços em Ecologia / Meio Ambiente",
      "Serviços em Eletroeletrônica / Metal Mecânica",
      "Serviços em Festas / Eventos",
      "Serviços em Informática",
      "Serviços em Internet",
      "Serviços em Jóias / Relógios / Óticas",
      "Serviços em Telefonia",
      "Serviços em Veículos",
      "Serviços Esotéricos / Místicos",
      "Serviços Financeiros",
      "Serviços Funerários",
      "Serviços Gerais",
      "Serviços Gráficos / Editoriais",
      "Serviços para Animais",
      "Serviços para Deficientes",
      "Serviços para Escritórios",
      "Serviços para Roupas",
      "Serviços Socias / Assistenciais",
      "Sex Shop",
      "Shopping Centers",
      "Tabacaria",
      "Tarifas Bancárias",
      "Tarifas Telefônicas",
      "Telefonia",
      "Turismo",
    ],
  };
  useEffect(() => {
    const { nomeFantasia, tipoCliente, segmento, tipoPessoa } = registerClient;
    if (
      nomeFantasia !== "" &&
      tipoCliente !== "" &&
      segmento !== "" &&
      tipoPessoa !== ""
    ) {
      setRegisterClientComplete(true);
      dispatch(sendFormAction(registerClient));
    } else {
      setRegisterClientComplete(false);
    }
  }, [registerClient, setRegisterClientComplete, dispatch]);

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  useEffect(() => {
    setRegisterClient(stateFormEdit);
    console.log("stateFormEdit", stateFormEdit);
  }, [stateFormEdit]);

  function sendForm(e) {
    e.preventDefault();

    const id = form.id;

    console.log("FORM_EDIT_ALTERED", form);

    dispatch(EditCompleteAction(form, id, token, history));
    localStorage.removeItem("@useStatePerist:@generalFields");
    localStorage.removeItem("@useStatePerist:@arrayObservation");
    localStorage.removeItem("@useStatePerist:@arrayDocuments");
    localStorage.removeItem("@useStatePerist:@arrayContact");
    localStorage.removeItem("@useStatePerist:@invoiceFields");
    localStorage.removeItem("@useStatePerist:@addressFields");
    localStorage.removeItem("@useStatePerist:@addressDeliveryFields");
    localStorage.removeItem("@useStatePerist:@contactFields");
    localStorage.removeItem("@useStatePerist:@generalFields");
    localStorage.removeItem("@useStatePerist:@documentsFields");
    localStorage.removeItem("@useStatePerist:@observationFields");
    localStorage.removeItem("@useStatePersist:@checkbox");
    localStorage.removeItem("@useStatePersist:@checboxRegister");
    localStorage.removeItem("@useStatePersist:@checboxRegister");
    localStorage.removeItem("@useStatePersist:@documentPerson");
  }

  return (
    <>
      <ContainerDescriptions>
        <TextRegister>EDITAR CLIENTE</TextRegister>
        <ButtonSave
          onClick={sendForm}
          disabled={allFieldsComplete ? false : true}
          color={allFieldsComplete ? "green" : "#AEAEAE"}
        >
          Salvar
        </ButtonSave>
        <ContainerParagraf>
          <Paragraf>
            {allFieldsComplete
              ? ""
              : "É preciso preencher todos os campos para finalizar o cadastro*"}
          </Paragraf>
        </ContainerParagraf>
      </ContainerDescriptions>
      <ContainerInfo>
        <Form>
          <TextField
            name="fantasyName"
            label="Nome Fantasia"
            variant="outlined"
            type="text"
            style={{ marginTop: 12, width: 260, margin: 6, marginLeft: 7 }}
            value={registerClient.nomeFantasia}
            onChange={(event) =>
              setRegisterClient({
                ...registerClient,
                nomeFantasia: event.target.value,
              })
            }
          />
          <TextField
            name="typeClient"
            label="Tipo"
            variant="outlined"
            disabled={true}
            type="text"
            style={{ marginTop: 12, width: 260, margin: 6, marginLeft: 15 }}
            value={registerClient.tipoCliente}
            onChange={(event) =>
              setRegisterClient({
                ...registerClient,
                tipoCliente: event.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <img src={cadeado} width="15" alt="cep" height="15" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <ContainerSwitchButton
            style={{ fontFamily: "Roboto", fontSize: 12, marginLeft: 7 }}
          >
            {" "}
            <div>
              <p>Ativo</p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={registerClient.ativo}
                      onChange={(event) => {
                        setRegisterClient({
                          ...registerClient,
                          ativo: event.target.checked,
                        });
                      }}
                    />
                  }
                  // label={name}
                />
              </FormGroup>
            </div>
            <div style={{ marginLeft: 4 }}>
              <p>Marketing</p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={registerClient.marketing}
                      onChange={(event) => {
                        setRegisterClient({
                          ...registerClient,
                          marketing: event.target.checked,
                        });
                      }}
                    />
                  }
                  // label={name}
                />
              </FormGroup>
            </div>
          </ContainerSwitchButton>
        </Form>
        <Form>
          <FormControl
            variant="outlined"
            style={{ marginTop: 12, width: 260, margin: 6 }}
            name="segment"
            floatingLabelText="Segmento"
            value={registerClient.segmento}
            onChange={(event) =>
              setRegisterClient({
                ...registerClient,
                segmento: event.target.value,
              })
            }
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Segmento
            </InputLabel>
            <Select native label="Segmento" value={registerClient.segmento}>
              <option aria-label="None" value="" />
              {segmento.ramos_de_atividade.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ marginTop: 12, width: 260, margin: 6, marginLeft: 14 }}
            name="segment"
            value={registerClient.tipoPessoa}
            onChange={(event) =>
              setRegisterClient({
                ...registerClient,
                tipoPessoa: event.target.value,
              })
            }
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Tipo de Pessoa
            </InputLabel>
            <Select
              native
              label="Tipo de Pessoa"
              value={registerClient.tipoPessoa}
            >
              <option aria-label="None" value="" />
              <option value={"Física"}>Física</option>
              <option value={"Jurídica"}>Jurídica</option>
            </Select>
          </FormControl>

          <ContainerSwitchButton
            style={{ fontFamily: "Roboto", fontSize: 12, marginLeft: 10 }}
          >
            <div>
              <p>Prospect</p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={registerClient.prospect}
                      onChange={(event) => {
                        setRegisterClient({
                          ...registerClient,
                          prospect: event.target.checked,
                        });
                      }}
                    />
                  }
                  // label={name}
                />
              </FormGroup>
            </div>
            <div style={{ marginTop: -12.8, marginLeft: 4 }}>
              <p>Marketing</p>
              <p>Datatem</p>
              <FormGroup>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={registerClient.marketingDatatem}
                      onChange={(event) => {
                        setRegisterClient({
                          ...registerClient,
                          marketingDatatem: event.target.checked,
                        });
                      }}
                    />
                  }
                  // label={name}
                />
              </FormGroup>
            </div>
          </ContainerSwitchButton>
        </Form>

        <TabsEdit
          setData={setData}
          registerClientComplete={registerClientComplete}
        />
      </ContainerInfo>
    </>
  );
}

export default RegisterClient;
