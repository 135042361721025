import axios from "axios";

// const endpoint = "https://sankhya-web-backend-prd.ingress.datatem.com.br";
const endpoint = process.env.REACT_APP_API_URL || "https://sankhya-web-backend-dev.ingress.datatem.com.br/";

const api = axios.create({
  baseURL: endpoint,
});

export default api;
