import { useEffect } from "react";
import { Container, ContainerSelect, Image, ButtonSave } from "./styles";
import Nuvem from "../../../../assets/nuvem.svg";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DocumentList from "./DocumentList";
import { useStatePersist as useState } from "use-state-persist";
import { useDispatch } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";

function DocumentAttachments({ setDocumentComplete }) {
  const dispatch = useDispatch();

  const [arrayDocument, setArrayDocument] = useState("@arrayDocuments", {
    anexos: [],
  });
  const [document, setDocument] = useState("@documentsFields", {
    nome: "",
    anexo: "",
    descricao: "",
  });


  console.log("anexos",arrayDocument)

  function changeHandler(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setDocument({ ...document, anexo: reader.result });
    };
    reader.onerror = (error) => console.log("DOCUMENT_ERROR", error);
  }

  useEffect(() => {
    if (arrayDocument.length !== 0) {
      setDocumentComplete(true);
      dispatch(sendFormAction(arrayDocument));
    } else {
      setDocumentComplete(false);
      dispatch(sendFormAction({}));
    }
  }, [arrayDocument, setDocumentComplete, dispatch]);

  function onSubmit(e) {
    e.preventDefault();

    if (document.anexo !== "" && document.descricao !== "") {
      setArrayDocument({
        ...arrayDocument,
        anexos: [...arrayDocument.anexos, document],
      });
      setDocument({
        nome: "",
        anexo: "",
        descricao: "",
      });
    }
  }

  return (
    <Container>
      <ContainerSelect>
        <Image src={Nuvem} />
        <Button
          component="label"
          style={{ border: "1px solid gray", width: 270 }}
        >
          Selecione o Arquivo
          <input type="file" hidden onChange={changeHandler} />
        </Button>
        <form onSubmit={onSubmit}>
          <TextField
            label="Descrição do Arquivo"
            name="descriptionFile"
            type="text"
            style={{ width: 480 }}
            InputLabelProps={{
              shrink: true,
            }}
            value={document.descricao}
            onChange={(event) => {
              setDocument({
                ...document,
                descricao: event.target.value,
              });
            }}
          />

          <ButtonSave
            type="submit"
            style={{
              backgroundColor:
                document.descricao !== "" && document.anexo !== ""
                  ? "#00973C"
                  : "#AEAEAE",
            }}
          >
            Salvar
          </ButtonSave>
        </form>
      </ContainerSelect>

      {arrayDocument.anexos.length !== 0
        ? arrayDocument.anexos.map((item) => (
            <DocumentList descricao={item.descricao} />
          ))
        : null}
    </Container>
  );
}

export default DocumentAttachments;
