import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
`;

export const Text = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: 27px;
  margin-top: 12px;
`;

export const Description = styled.p`
  margin-top: 7px;
  font-family: "Roboto", sans-serif;
  color:gray;
  font-size:14.2px;
  text-align: center;
`;

export const SignInButton = styled.button`
  width: 420px;
  margin-top: 6px;
  margin-left:1.4%;
  height: 46px;
  color: #0b2138;
  border: 0px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 21px;
  cursor: pointer;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SucessIcon = styled.img``;

export const LinkText = styled.a`
  margin-top: 58px;
  color: #1565d8;
  cursor: pointer;

  :hover {
    border-bottom: 0.1px solid #1565d8;
  }
`;
