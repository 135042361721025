import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendFormAction } from "../../../../reducers/registerClient/actions";

import { Container } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import ObservationList from "./ObservationList";
import { useStatePersist as useState } from "use-state-persist";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function Observation({ setObservationComplete }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [observation, setObservation] = useState("@observationFields", {
    data: "",
    observacao: "",
  });

  const [arrayObservation, setArrayObservation] = useState(
    "@arrayObservation",
    {
      observacoes: [],
    }
  );

  useEffect(() => {
    if (arrayObservation.observacoes) {
      setObservationComplete(true);
      dispatch(sendFormAction(arrayObservation));
    } else {
      dispatch(sendFormAction([]));
      setObservationComplete(false);
    }
  }, [arrayObservation, setObservationComplete]);

  function onSubmit(e) {
    e.preventDefault();

    if (observation.data !== "" && observation.observacao !== "") {
      setArrayObservation({
        ...arrayObservation,
        observacoes: [...arrayObservation.observacoes, observation],
      });

      setObservation({
        data: "",
        observacao: "",
      });
    }
  }

  return (
    <Container>
      <Form className={classes.container} noValidate onSubmit={onSubmit}>
        <TextField
          name="date"
          label="Data"
          defaultValue="24-05-2018"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={observation.data}
          onChange={(event) =>
            setObservation({
              ...observation,
              data: event.target.value,
            })
          }
        />

        <TextField
          name="observation"
          label="Observação"
          type="text"
          style={{
            marginTop: 12,
            width: 700,
            margin: 6,
            backgroundColor: "white",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={observation.observacao}
          onChange={(event) =>
            setObservation({
              ...observation,
              observacao: event.target.value,
            })
          }
        />

        <ButtonSave
          style={{
            backgroundColor:
              observation.data !== "" && observation.observacao !== ""
                ? "green"
                : "#aeaeae",
          }}
          type="submit"
        >
          Salvar
        </ButtonSave>
      </Form>

      {arrayObservation.observacoes && (
        <div
          style={{
            marginTop: 23,
            display: "flex",
            justifyContent: "space-around",
            width: 230,
          }}
        >
          <span style={{ color: "gray" }}>Data</span>
          <span style={{ color: "gray" }}>Observação</span>
        </div>
      )}

      {arrayObservation?.observacoes?.map((item) => (
        <ObservationList data={item.data} observacao={item.observacao} />
      ))}
    </Container>
  );
}

const Form = styled.form``;
export const ButtonSave = styled.button`
  background-color: #aeaeae;
  width: 93px;
  height: 33px;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  outline: none;
`;

export default Observation;
